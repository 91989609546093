import {Fields} from "./Fields";
import {IVE_TETE_DOCUM} from "./VE_TETE_DOCUM";
import {IVE_PIED_DOCUM} from "./VE_PIED_DOCUM";
import {PackHeuresStatus} from "./PackHeuresStatus";

export interface IPackHeures {
    Id: number;

    Societe: string;

    VeTeteDocumAccompte: IVE_TETE_DOCUM;

    AccompteHt: number;
    AccompteTtc: number;
    CommandeHt: number;
    CommandeTtc: number;
    FactureHt: number;
    FactureTtc: number;
    SoldeHt: number;
    SoldeTtc: number;
    Status: PackHeuresStatus;

    VePiedsDocumDeductionCommandes: Array<IVE_PIED_DOCUM>;
    VePiedsDocumDeductionFactures: Array<IVE_PIED_DOCUM>;

}

export const PackHeures = Fields<IPackHeures>();