import List from 'devextreme-react/list';
import { navigation } from '../../app-navigation';
import FullNavigationMenuItem from './full-navigation-menu-item';

import './full-navigation-menu.scss';


export default function FullNavigationMenu (props: {onNavigationChanged: any}) {
	return (<List
		dataSource={
			navigation.map(item => (
				{
					...item, 
					'key': item.text, 
					items: item.items?.filter(subItem => subItem.type.includes('scanette'))
				}
			)).filter(item => item.type.includes('scanette'))
		}
		grouped={true}
		height="100%"
		groupRender={FullNavigationMenuItem}
		onItemClick={props.onNavigationChanged} />);
};
