import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import {ConfType} from "../contexts/conf";
import {IVE_TETE_DOCUM} from "../models/VE_TETE_DOCUM";

export const getPrestations = (conf: ConfType, data: IVE_TETE_DOCUM) => new DataSource({
    store: new ODataStore({
        version: 4,
        withCredentials: false,
        url: conf.oasisApiUrl + 'OdataTetesDocumentsVente(CODE_SOCIETE=' + data.CODE_SOCIETE + ',TY_TRAIT_DOC_VENTE=' + data.TY_TRAIT_DOC_VENTE + ',NO_DOCUMENT=' + data.NO_DOCUMENT + ')/GetPrestations',
        key: 'Id',
        beforeSend: (request: any) => {
            const token: string | null = localStorage.getItem('oasisWebUser') || sessionStorage.getItem('oasisWebUser');

            if (token) {
                request.headers["Authorization"] = "Bearer " + token;
            }
        },
        onLoaded: function (result) {
            console.log("onLoaded");
            console.log(result);
        }
    }),
    sort: [
        {selector: 'Id', desc: true}
    ]
});


export interface getPrestationsReportReturn {
    Title: string,
    PdfDocumentBase64: string
}


export function getPrestationsReport(conf: ConfType, noIdDocuments: number[]): Promise<getPrestationsReportReturn | void> {

    const token: string | null = localStorage.getItem('oasisWebUser') || sessionStorage.getItem('oasisWebUser');

    return fetch(conf.oasisApiUrl + 'RunReport',
        {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                report: "MA001DecomptePrestations",
                reportParamters:
                    [
                        {
                            Name: "noIdDocmuments",
                            Values: noIdDocuments.map(value => value.toString())
                        }
                    ]
            })
        })
        .then(response => {

            if (response.ok)
            {
                return response.json();
            }
            else
            {
                throw new Error("Status " + response.status + " " +response.statusText);
            }


        })
        .then(responseJson => {
            return responseJson as getPrestationsReportReturn
        })
}

