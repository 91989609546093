import React, { useState, useEffect, createContext, useContext } from 'react';
import ODataContext from 'devextreme/data/odata/context';
import oasisContext from '../odata/oasis';

export type ConfType = {
    modes: string[],
    oasisApiUrl: string,
    applicationName: string,
    basename: string,
    defaultCurrency: string
};

type ConfContextType = {
    conf: ConfType,
    oDataStore: ODataContext | null
};

const ConfContext = createContext<ConfContextType>({
    conf: {modes: [], oasisApiUrl: "", applicationName:"", basename:"/", defaultCurrency:"CHF"},
    oDataStore: null
});

const useConf = () => useContext(ConfContext);

function ConfProvider(props: any) {
    const [conf, setConf] = useState<ConfType>({modes: [], oasisApiUrl: "", applicationName: "", basename:"/", defaultCurrency:"CHF"});
    const [oDataStore, setODataStore] = useState<ODataContext | null>(null);
    
    useEffect(() => {

        (async function () {
            await fetch('conf.json')
            .then((res) => res.json())
            .then((data) => {
                
                /*let config: ConfType = {...conf,
                    applicationName: data.applicationName,
                    oasisApiUrl: data.oasisApiUrl,
                    basename: data.basename,
                    defaultCurrency: data.defaultCurrency
                }*/
                let config: ConfType = {...conf, ...data}
                config.modes = [];
                for (const mode in data.modes) {
                    if (data.modes[mode] === true) {
                        config.modes.push(mode);
                    }
                }
                
                setConf(config);
            })
            .catch((err) => {
                console.log(err);
            });

        })();

    }, []);

    useEffect(() => {
        
        if (conf.oasisApiUrl.length) {
            setODataStore(new ODataContext({
                ...oasisContext,
                url: conf.oasisApiUrl
            }));
        }
    }, [conf]);

    return (
        <ConfContext.Provider value={{ conf, oDataStore }} {...props} />
    );
}

export { ConfProvider, useConf }