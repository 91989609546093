import { useMemo } from 'react';
import ContextMenu, { Position } from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';

import { useAuth } from '../../contexts/auth';

import './user-panel.scss';

interface IProps {
  menuMode: 'context' | 'list'
}

export default function UserPanel ({ menuMode }: IProps) {
  const { user, logOut } = useAuth();

  const getDataWedgeConfig = (): void => {
    fetch('dwprofile_Oasis.db', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-sqlite3',
      },
    })
    .then((response: Response) => response.blob())
    .then((blob: Blob) => {
      // Create blob link to download
      const url: string = window.URL.createObjectURL(
        new Blob([blob]),
      );

      const link: HTMLAnchorElement = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        'dwprofile_Oasis.db',
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode?.removeChild(link);
    });
  }

  const menuItems = useMemo(() => ([
    /*{
      text: 'Profile',
      icon: 'user'
    },*/
    {
      text: 'Configuration DataWedge',
      icon: 'preferences',
      onClick: getDataWedgeConfig
    },
    {
      text: 'Déconnexion',
      icon: 'runner',
      onClick: logOut
    }
  ]), [logOut]);

  return (
    <div className={'user-panel'}>
      <div className={'user-info'}>
        <div className={'image-container'}>
          <div
            style={{
              background: `url(${user?.avatarUrl}) no-repeat #fff`,
              backgroundSize: 'cover'
            }}
            className={'user-image'} />
        </div>
        <div className={'user-name'}>{user?.User}</div>
      </div>

      {menuMode === 'context' && (
        <ContextMenu
          items={menuItems}
          target={'.user-button'}
          showEvent={'dxclick'}
          width={210}
          cssClass={'user-menu'}
        >
          <Position my={{ x: 'top', y: 'center' }} at={{ x: 'bottom', y: 'center' }} />
        </ContextMenu>
      )}
      {menuMode === 'list' && (
        <List className={'dx-toolbar-menu-action'} items={menuItems} />
      )}
    </div>
  );
}
