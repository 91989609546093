import Notify from "devextreme/ui/notify";

export const OasisDateToNormal = (e: number) => {
    const date = new Date(1899, 11, 30, 0, 0, 0);
    date.setDate(date.getDate() + (e - 693594));
    return date;
}

export const NormalDateToOasis = (e: Date) => {
    //const date: any = new Date(e);
    const date: any = e;
    const start: any = new Date(1899, 11, 30, 0, 0, 0);
    var diff = date - start;
    var oneDay = 1000 * 60 * 60 * 24;
    return Math.round((diff/oneDay) + 693594);
}

export const nl2br = (str: string) => {
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2');
}

export const cropText = (txt: any, length: number) => {
    let cropped = txt.substr(0, length);
    return cropped + (txt.length > length ? '...' : '');
}

export const ShowToast = (message: string, type: "error" | "info" | "success" | "warning") => {
    Notify(
        {
            message: message,
            width: 230
        },
        type
    );
}
