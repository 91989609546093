import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { useConf } from './conf';
import { getUser, signIn, signOut, resetPassword, changePassword } from '../api/auth';

interface GnPersonnes {
    ACCESS_WEB: boolean
    ACTIF_DANS_SOCIETE: boolean
    ADRESSE_EMAIL: string
    CODE_LANGUE: number
    CODE_REPRESENTANT: string
    CODE_SEXE: number
    CODE_SOCIETE: number
    DLU: string
    ID: number
    INTITULE_ADRESSE: string
    INTITULE_COURRIER: string
    MOT_PASSE_HASH: string
    NOM: string
    NO_ADRESSE_PRIVEE: number
    NO_ADRESSE_SOCIETE: number
    NO_BIP: string
    NO_FAX: string
    NO_INTERNE: string
    NO_NATEL: string
    NO_PERSONNE: number
    NO_TELEPHONE_D1: string
    NO_TELEPHONE_D2: string
    PRENOM: string
    PROFESSION_FONCTION: string
    TITRE_MONSIEUR: string
}

interface GnUser {
    CD_UTILISATEUR: string
    NOM_UTILISATEUR: string
    CD_GROUPE: string
    CODE_LANGUE: number
    SUPER_DROITS: boolean
    ATTRIBUTION_TYPE: number
    ATTRIB_GROUP_USER: string
    SOCIETE_DEFAUT: number
    INFO_GENERIQUE: string
    ADRESSE_EMAIL: string
    MOT_PASSE_DT: number
    ID_SAV_DEPARTEMENT: number

    CODE_SOCIETE: number
    NO_EXERCICE: number
    NO_CENTRE: string
    CD_FILIALE: string
    CD_DEPOT: string
}

interface IUser {
    login: string;
    avatarUrl: string;
    AuthorizationType: string;
    User: String;
    GnPersonnes: GnPersonnes | null;
    GnUser: GnUser | null;
}

interface NotificationOptions {
    isVisible: boolean;
    message: string;
    type: "error" | "custom" | "info" | "success" | "warning" | undefined
}

type AuthContextType = {
    user: IUser | null;
    loading: boolean
    logIn?: (login: string, password: string, rememberMe?: boolean, setLoading?: React.Dispatch<boolean>, setNotification?: React.Dispatch<NotificationOptions>) => Promise<any>;
    logOut?: () => Promise<void>;
    resetPasswordEmail?: (login: string, setNotification?: React.Dispatch<NotificationOptions>) => Promise<any>;
    changePasswordToken?: (password: string, token: string, setNotification?: React.Dispatch<NotificationOptions>) => Promise<any>;
};

const AuthContext = createContext<AuthContextType>({user: null, loading: false});
const useAuth = () => useContext(AuthContext);

function AuthProvider(props: any) {
    const { conf } = useConf();
    const [user, setUser] = useState<IUser | null>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (conf.oasisApiUrl.length) {
            (async function () {
            const result = await getUser(conf.oasisApiUrl);
            if (result.isOk) {
                setUser(result.data);
            }

            setLoading(false);
        })();
        }

    }, [conf]);

    const logIn = useCallback(async (login, password, rememberMe, setLoading) => {
        const result = await signIn(conf.oasisApiUrl, login, password, rememberMe);

        if (result.isOk) {

            const user = await getUser(conf.oasisApiUrl);
            if (user.isOk) {
                setUser(user.data);
            }
            else {
                if (setLoading) {
                    setLoading(false);
                }
            }

            return user;
        }
        else {
            if (setLoading) {
                setLoading(false);
            }
        }

        return result;
        
    }, [conf]);

    const logOut = useCallback(() => {
        const result = signOut();

        if (result.isOk) {
            setUser(null);
        }

        return result;
    }, [conf]);

    const resetPasswordEmail = useCallback(async (login) => {
        const result = await resetPassword(conf.oasisApiUrl, login);
        return result;
    }, [conf]);

    const changePasswordToken = useCallback(async (password, token) => {
        const result = await changePassword(conf.oasisApiUrl, password, token);
        return result;
    }, [conf]);

    return (
        <AuthContext.Provider value={{ user, logIn, logOut, resetPasswordEmail, changePasswordToken, loading }} {...props} />
    );
}



export { AuthProvider, useAuth }