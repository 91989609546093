import './full-navigation-menu-item.scss';

interface IMenuItem {
	icon?: string,
	type: string[],
	path: string,
	text: string
}



export default function FullNavigarionMenuItem (item: IMenuItem) {
	return (<div className="full-menu-item">
		<i className={`dx-icon dx-icon-${item.icon}`}></i>
		<p>{item.text}</p>
	</div>);
}
