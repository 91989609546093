import {Fields} from "./Fields";
import {IST_ARTICLE} from "./ST_ARTICLE";

export interface IYI_PALETTE {
    ID: number;
    CODE_SOCIETE: number;
    REFERENCE_ARTICLE_A: string;
    ParentST_ARTICLE: IST_ARTICLE;
    QUANTITE: number;
    DATE_ENTREE: Date;
    DATE_SORTIE: Date;
    DLC: string;
    STATUT: number;
}

export const YI_PALETTE = Fields<IYI_PALETTE>();