import { useState, useRef, useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Form, {
    Item,
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule,
    CustomRule,
    PatternRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';


import { useAuth } from '../../contexts/auth';

import './change-password-form.scss';

export default function ChangePasswordForm (props: {setNotification: any}) {

    const { search } = useLocation();
    
    const { setNotification } = props;
    const history = useHistory();
    const { changePasswordToken } = useAuth();
    const [token, setToken] = useState('');
    const [loading, setLoading] = useState(false);
    const formData = useRef({ password: '', passwordConfirm: '' });
    

    useEffect(() => {
        setToken(new URLSearchParams(search).get('token') || '');
    }, [search]);

    const onSubmit = useCallback(async (e) => {
        
        e.preventDefault();
        
        const { password } = formData.current;
        
        if (changePasswordToken && token.length) { 
            setLoading(true);
            const change = await changePasswordToken(password, token);
            if (change.isOk === true) {
                setNotification({isVisible: true, message: change.message, type: "success"});
                history.push('/login');
            }
            else {
                setNotification({isVisible: true, message: change.message,  type: "error"});
                setLoading(false);
            }
            
        }

    }, [changePasswordToken, token]);

    const confirmPassword = useCallback(
        ({ value }) => value === formData.current.password,
        []
    );

    return (<>
    {!token.length ? <p>Aucun token</p> : ''}
    <form className={'change-password-form'} onSubmit={onSubmit}>
        <Form formData={formData.current} disabled={loading} visible={token.length ? true : false}>
            
            <Item
                dataField={'password'}
                editorType={'dxTextBox'}
                editorOptions={passwordEditorOptions}
            >
                <RequiredRule message="Le mot de passe est requis" />
                <PatternRule pattern='(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}' message="Le mot de passe doit contenir minimum 8 caractères, comprenant au moins une lettre majuscule et un chiffre"/>
                <Label visible={false} />
            </Item>
            <Item
                dataField={'confirmedPassword'}
                editorType={'dxTextBox'}
                editorOptions={confirmedPasswordEditorOptions}
            >
                <RequiredRule message="Le mot de passe est requis" />
                <PatternRule pattern='(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}' message="Le mot de passe doit contenir minimum 8 caractères, comprenant au moins une lettre majuscule et un chiffre"/>
                <CustomRule
                    message={'Le mot de passe ne correspond pas'}
                    validationCallback={confirmPassword}
                />
                <Label visible={false} />
            </Item>
            
            <ButtonItem>
                <ButtonOptions
                    width={'100%'}
                    type={'default'}
                    useSubmitBehavior={true}
                >
                    <span className="dx-button-text">
                        { loading ? <LoadIndicator width={'24px'} height={'24px'} visible={true} /> : 'Changer le mot de passe' }
                    </span>
                </ButtonOptions>
            </ButtonItem>
        </Form>
    </form></>);
}

const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Mot de passe', mode: 'password' };
const confirmedPasswordEditorOptions = { stylingMode: 'filled', placeholder: 'Confirmer le mot de passe', mode: 'password' };