import { Switch, Route, Redirect } from 'react-router-dom';

import routes from './app-routes';
import AuthenticatedLayout from './layouts/authenticated/authenticated';
import Footer from './components/footer/footer';
import {useConf} from "./contexts/conf";


export default function Content (props: {appMode: string}) {

	const { conf } = useConf();

	return (
		<AuthenticatedLayout title={conf.applicationName} appMode={props.appMode}>
			<Switch>
				{routes.map(({ path, component }) => (
					<Route
						exact
						key={path}
						path={path}
						component={component}
					/>
				))}

				<Redirect to={'/home'} />
			</Switch>

			<Footer>
				Copyright © 2011-{new Date().getFullYear()} Oasis Web
				<br />
				All trademarks or registered trademarks are property of their
				respective owners.
				<span style={{float: 'right', fontSize: '0.7em', color: '#999'}}>Version 0.2</span>
			</Footer>
		</AuthenticatedLayout>
	);
}