import React, {useEffect, useRef, useState} from 'react';

import DataGrid, {
    Column,
    FilterRow,
    GroupPanel,
    Item, Lookup,
    MasterDetail,
    Pager,
    Paging,
    SearchPanel,
    Toolbar
} from 'devextreme-react/data-grid';
import {useConf} from '../../contexts/conf';
import {useAuth} from '../../contexts/auth';

import './hours-packs.scss';
import {GetPacksHeuresDatasource} from "../../odata/packsHeures";
import {GetPacksHeuresDatasourceByName} from "../../odata/packsHeures";
import {PackHeures, IPackHeures} from "../../models/PackHeures";
import {VE_TETE_DOCUM} from "../../models/VE_TETE_DOCUM";
import SoldeCellRender from "./SoldeCellRender";
import {Button as DateRangeBoxButton, DateRangeBox} from "devextreme-react/date-range-box";
import {ValueChangedEvent} from "devextreme/ui/date_range_box";
import {PackHeuresStatus} from "../../models/PackHeuresStatus";
import DetailTab from "./detailTab";
import DataSource from 'devextreme/data/data_source';


export default function HoursPacks() {

    const {conf} = useConf();
    const {user} = useAuth();

    const getFromDate = () => new Date(new Date().setFullYear(new Date().getFullYear() - 2));
    const getToDate = () => new Date();

    const [fromDate, setFromDate] = useState<Date>(getFromDate());
    const [toDate, setToDate] = useState<Date>(getToDate());
    const [packsHeuresDatasource, setPacksHeuresDatasource] = useState<DataSource<IPackHeures, IPackHeures['Id']>>();

    const rangeDateBoxRef = useRef<DateRangeBox>(null);

    //const packsHeuresDatasource = GetPacksHeuresDatasource(conf, user?.GnPersonnes?.CODE_SOCIETE as number || 1, fromDate, toDate);
    
    useEffect(() => {
        if (user && (user.GnUser || user.GnPersonnes)) {
            if(user.GnUser){
                setPacksHeuresDatasource(GetPacksHeuresDatasourceByName(conf, user.GnUser.CD_UTILISATEUR, fromDate, toDate));
            }
            else{
                const codeSociete = user.GnPersonnes?.CODE_SOCIETE;
                if (codeSociete) {
                    setPacksHeuresDatasource(GetPacksHeuresDatasource(conf, codeSociete, fromDate, toDate));
                }
            }
            
            
        }
    }, [user, fromDate, toDate]);

    function onSelectedDateRangeValueChange(e: ValueChangedEvent) {

        setFromDate(e.value[0]);
        setToDate(e.value[1]);
    }
    
    const currencyFormat = {type: "currency", precision: 2, currency: "CHF"};

    return (

        <React.Fragment>
            <h2 className={'content-block'}>Packs d'heures</h2>
            <div className={'content-block'}>
                <div className={'dx-card'}>

                    <DataGrid
                        className={'dx-card wide-card'}
                        dataSource={packsHeuresDatasource}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        focusedRowEnabled={true}
                        rowAlternationEnabled={true}
                    >
                        <Toolbar>
                            <Item location="center">
                                <DateRangeBox
                                    ref={rangeDateBoxRef}
                                    defaultStartDate={fromDate}
                                    defaultEndDate={toDate}
                                    width={400}
                                    onValueChanged={onSelectedDateRangeValueChange}>
                                    <DateRangeBoxButton name="dropDown"/>
                                </DateRangeBox>
                            </Item>
                            <Item name="groupPanel"/>
                            <Item name="searchPanel"/>
                        </Toolbar>

                        <SearchPanel visible={true}/>

                        {(user && user.AuthorizationType === "User") &&
                            <GroupPanel visible={true}/>}

                        {(user && user.AuthorizationType === "User") &&
                            <Column
                                dataField={`${PackHeures.Societe}`}
                                caption={"Societe"}
                                sortIndex={0}
                                groupIndex={0}/>
                        }

                        {(user && user.AuthorizationType === "User") &&
                            <Column
                                dataField={`${PackHeures.VeTeteDocumAccompte}.${VE_TETE_DOCUM.RAISON_SOCIALE_NOM}`}
                                caption={"Nom client"}
                                sortIndex={0}
                                groupIndex={0}/>
                        }

                        <Column
                            dataField={`${PackHeures.VeTeteDocumAccompte}.${VE_TETE_DOCUM.DATE_DOCUMENTDateTime}`}
                            caption={"Date"}
                            sortIndex={1}
                            sortOrder="desc"/>
                        <Column dataField={`${PackHeures.VeTeteDocumAccompte}.${VE_TETE_DOCUM.NO_DOCUMENT}`}
                                caption={"No acompte"}
                                sortIndex={2}
                                sortOrder="desc"/>
                        <Column dataField={`${PackHeures.VeTeteDocumAccompte}.${VE_TETE_DOCUM.CONCERNE}`}
                                caption={"Concerne"}/>
                        <Column dataField={`${PackHeures.AccompteTtc}`}
                                format={currencyFormat}/>
                        <Column dataField={`${PackHeures.CommandeTtc}`}
                                caption={"Commandé Ttc"}
                                format={currencyFormat}/>
                        <Column dataField={`${PackHeures.FactureTtc}`}
                                caption={"Facturé Ttc"}
                                format={currencyFormat}/>
                        <Column dataField={`${PackHeures.SoldeTtc}`}
                                format={currencyFormat}/>

                        <Column
                            caption="Solde %"
                            dataType="number"
                            format="percent"
                            alignment="right"
                            allowGrouping={false}
                            cellRender={SoldeCellRender}
                            width={120}
                        />

                        <Column dataField={`${PackHeures.Status}`} filterValue={PackHeuresStatus.Solde} allowSearch={false}>
                            <Lookup
                                dataSource={Object.values(PackHeuresStatus)}
                            />
                        </Column>

                        <FilterRow visible={true}/>

                        <Paging defaultPageSize={10}/>

                        <Pager
                            visible={true}
                            allowedPageSizes={[5, 10, 50]}
                            showPageSizeSelector={true}
                            showInfo={true}
                            showNavigationButtons={true}
                        />

                        <MasterDetail
                            enabled={true}
                            component={DetailTab}
                        />

                    </DataGrid>

                </div>
            </div>
        </React.Fragment>
    )
};