import React from 'react';

import DataGrid, {Column, Paging} from 'devextreme-react/data-grid';

import './deductions.scss';
import {IPackHeures} from "../../models/PackHeures";
import {IVE_PIED_DOCUM, VE_PIED_DOCUM} from "../../models/VE_PIED_DOCUM";
import {VE_TETE_DOCUM} from "../../models/VE_TETE_DOCUM";


interface IDeductionProps {
    data: IPackHeures
    type: "Commande" | "Facture"
}

export default (props: IDeductionProps) => {


    let dataSource: Array<IVE_PIED_DOCUM>;
    switch (props.type) {
        case "Commande":
            dataSource = props.data.VePiedsDocumDeductionCommandes;
            break;
        case "Facture":
            dataSource = props.data.VePiedsDocumDeductionFactures;
            break;
    }

    return (
        <React.Fragment>
            <DataGrid
                className={'dx-card wide-card'}
                dataSource={dataSource}
                keyExpr={`${VE_PIED_DOCUM.NO_DOCUMENT}`}
                allowColumnResizing={true}
                columnAutoWidth={true}
                focusedRowEnabled={true}
                rowAlternationEnabled={true}
                width={'auto'}>

                <Paging enabled={false}/>

                <Column
                    dataField={`${VE_PIED_DOCUM.ParentVE_TETE_DOCUM}.${VE_TETE_DOCUM.DATE_DOCUMENTDateTime}`}
                    caption={"Date document"}
                    sortIndex={0}
                    sortOrder="desc"/>
                <Column
                    dataField={`${VE_PIED_DOCUM.NO_DOCUMENT}`}
                    caption={props.type}
                    sortIndex={1}
                    sortOrder="desc"/>
                <Column
                    dataField={`${VE_PIED_DOCUM.MONTANT_LIGNE_PIED_TTC}`}
                    caption={"Montant TTC"}
                    format={{type: "currency",  precision: 2, currency: "CHF"}}/>

            </DataGrid>
        </React.Fragment>
    );
}