import {Fields} from "./Fields";
import {IVE_TETE_DOCUM} from "./VE_TETE_DOCUM";

export interface IVE_PIED_DOCUM {
    CODE_SOCIETE: number;
    TY_TRAIT_DOC_VENTE: number;
    NO_DOCUMENT: number;
    ParentVE_TETE_DOCUM: IVE_TETE_DOCUM;
    NO_POS_LIGNE_PIED: number;
// V_VE_PIED_DOCUM;
// LinkV_VE_PIED_DOCUM_ACOMPTE;
    CD_LIGNE: number;
    TY_TRAIT_LIGNE_PIED: number;
    TAUX_CALCUL_LIGNE_PIED: number;
    BASE_CALCUL_LIGNE_PIED: number;
    BASE_QTE_VALEUR_1: number;
    BASE_QTE_VALEUR_2: number;
    MONTANT_LIGNE_PIED: number;
    MONTANT_LIGNE_PIED_TTC: number;
    CD_TVA_TAUX: number;
    CD_TVA_TYPE: number;
    CD_INTEGRATION: number;
    CD_PRINT_LIGNE_PIED: number;
    NO_MONTANT_MEMO: number;
    NO_CENTRE: string;
    NO_PROJET: string;
    STRUCTURE_PROJET: string;
    TY_TRAIT_DOC_ACOMPTE: number;
    NO_DOC_ACOMPTE: number;
    NO_BON_ALPHA: string;
    NO_BON_INTERNE: number;
    UTILISE_COMME_ARRONDI: number;
}

export const VE_PIED_DOCUM = Fields<IVE_PIED_DOCUM>();