import React, {useEffect, useState} from 'react';
import {useConf} from '../../contexts/conf';

import DataSource from 'devextreme/data/data_source';
import DataGrid, {MasterDetail, FilterRow, Pager, Paging, Column, Selection} from 'devextreme-react/data-grid';
import {useAuth} from '../../contexts/auth';
import Prestations from './prestations';
import {OasisDateToNormal, NormalDateToOasis, ShowToast} from '../../utils/functions'
import './documents.scss';
import {VE_TETE_DOCUM} from "../../models/VE_TETE_DOCUM";
import Button from "devextreme-react/button";

import {SelectionChangedEvent} from "devextreme/ui/data_grid";
import {getPrestationsReport, getPrestationsReportReturn} from "../../odata/prestations";
import LoadIndicator from "devextreme-react/load-indicator";


export default function Documents() {

    const {oDataStore, conf} = useConf();
    const {user} = useAuth();

    const DataGridRef = React.createRef<DataGrid>()

    const [gridSelectionMode, setGridSelectionMode] = useState< "single" | "multiple" | "none">("single");
    const [printing, setPrinting] = useState<boolean>(false);


    const documents = new DataSource({
        // @ts-ignore
        store: oDataStore.OdataTetesDocumentsVente,
        select: [
            VE_TETE_DOCUM.ID,
            VE_TETE_DOCUM.NO_DOCUMENT,
            VE_TETE_DOCUM.DATE_DOCUMENT,
            VE_TETE_DOCUM.RAISON_SOCIALE_NOM,
            VE_TETE_DOCUM.CONCERNE,
            VE_TETE_DOCUM.SO_TOT_PIED_HT,
            VE_TETE_DOCUM.TY_TRAIT_DOC_VENTE,
            VE_TETE_DOCUM.CODE_SOCIETE
        ],
        filter: [
            [VE_TETE_DOCUM.DATE_DOCUMENT, '>', 738156],
            [
                [VE_TETE_DOCUM.CODE_SOCIETE, '=', 1],
                'or',
                [VE_TETE_DOCUM.CODE_SOCIETE, '=', 10],
                'or',
                [VE_TETE_DOCUM.CODE_SOCIETE, '=', 11]
            ],
            [VE_TETE_DOCUM.TY_TRAIT_DOC_VENTE, '=', 55],
            [
                [VE_TETE_DOCUM.TY_SAISIE_DOC, '=', 20],
                'or',
                [VE_TETE_DOCUM.TY_SAISIE_DOC, '=', 99]
            ]
        ]
    });

    const expandSelection = (e: SelectionChangedEvent) => {
        if (gridSelectionMode === "single") {
            e.component.collapseAll(-1);
            e.component.expandRow(e.currentSelectedRowKeys[0]);
        }
    }

    // const FilterOasisDate = (date: Date) => {
    //     const oasisDate = NormalDateToOasis(date);
    //     return [[VE_TETE_DOCUM.DATE_DOCUMENT, ">=", oasisDate], "and", [VE_TETE_DOCUM.DATE_DOCUMENT, "<=", oasisDate]];
    // }
    const FilterOasisDate = (filterValue: any, selectedFilterOperation: string | null, target: string) : any => {
        const oasisDate = NormalDateToOasis(filterValue);
        return [[VE_TETE_DOCUM.DATE_DOCUMENT, ">=", oasisDate], "and", [VE_TETE_DOCUM.DATE_DOCUMENT, "<=", oasisDate]];
    }

    const SortOasisDate = (date: Date) => {
        return NormalDateToOasis(date);
    }

      function selectClick() {
        DataGridRef.current?.instance.collapseAll(-1)
        setGridSelectionMode("multiple");
    }

    function printClick() {

        let noIdDocuments: number[] = [];

        DataGridRef.current?.instance.getSelectedRowsData().forEach((value) => {
            noIdDocuments.push(value.ID);
        });

        setPrinting(true);

        ShowToast("Impression en cours", "info");

        getPrestationsReport(conf, noIdDocuments).then((PrestationsReportReturn: getPrestationsReportReturn | void) => {

                ShowToast("Impression terminée", "success");

                const linkSource = "data:application/pdf;base64," + PrestationsReportReturn?.PdfDocumentBase64 + "\n";
                const downloadLink = document.createElement("a");
                const fileName = PrestationsReportReturn?.Title + ".pdf";

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            }
        )
            .catch(reason => ShowToast(`Erreur d'impression ${reason}` , "error"))
            .finally(() => setPrinting(false));
        setGridSelectionMode("single");
    }

    return (
        <React.Fragment>
            <h2 className={'content-block'}>Décompte de prestations</h2>
            <div className={'content-block'}>

                <div style={{display: 'flex', justifyContent: 'end'}}>
                    <Button icon="checklist" text="Sélectionner" onClick={selectClick}></Button>
                    <Button icon="print" text="Print" onClick={printClick} visible={!printing}></Button>
                    <Button icon="print" text="Print" onClick={printClick} visible={printing} disabled={true}>
                        <div>
                            <LoadIndicator
                                className="button-load-indicator"
                                visible={true}
                                height={20}
                                width={20}/>
                            <span className="dx-button-text">Impression en cours</span>
                        </div>
                    </Button>

                </div>

                <div className={'dx-card'}>

                    <DataGrid
                        id={"Grid"}
                        ref={DataGridRef}
                        className={'dx-card wide-card'}
                        dataSource={documents}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        focusedRowEnabled={true}
                        rowAlternationEnabled={true}
                        onSelectionChanged={expandSelection}>

                        <Selection mode={gridSelectionMode}/>
                        <FilterRow visible={true}/>

                        <Column dataField={VE_TETE_DOCUM.NO_DOCUMENT} caption="No. Facture" sortOrder={'desc'}/>
                        <Column dataField={VE_TETE_DOCUM.DATE_DOCUMENT} caption="Date"
                                calculateCellValue={(e: any) => OasisDateToNormal(e.DATE_DOCUMENT)}
                                allowFiltering={true}
                                calculateFilterExpression={FilterOasisDate}
                                calculateSortValue={(e: any) => SortOasisDate(e.DATE_DOCUMENT)}/>
                        {(user && user.AuthorizationType === "User") &&
                            <Column dataField={VE_TETE_DOCUM.RAISON_SOCIALE_NOM} caption="Raison Sociale"/>}
                        <Column dataField={VE_TETE_DOCUM.CONCERNE} caption="Concerne"/>
                        <Column dataField={VE_TETE_DOCUM.SO_TOT_PIED_HT} caption="Total"
                                format={{type: "currency", precision: 2}}/>

                        <Paging defaultPageSize={10}/>

                        <Pager
                            visible={true}
                            allowedPageSizes={[5, 10, 50]}
                            showPageSizeSelector={true}
                            showInfo={true}
                            showNavigationButtons={true}
                        />

                        <MasterDetail
                            enabled={false}
                            component={Prestations}
                        />

                    </DataGrid>

                </div>
            </div>
        </React.Fragment>
    )
};