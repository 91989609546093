import { useState, useRef, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Form, {
    Item,
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule,
    EmailRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';


import { useAuth } from '../../contexts/auth';
import { useConf } from '../../contexts/conf';

import './reset-password-form.scss';

export default function ResetPasswordForm (props: {setNotification: any}) {

    
    const { setNotification } = props;
    const history = useHistory();
    const { resetPasswordEmail } = useAuth();
    const { conf } = useConf();
	
    const [loading, setLoading] = useState(false);
    const [hasReset, setHasReset] = useState(false);
    const formData = useRef({ login: '' });
    

    const onSubmit = useCallback(async (e) => {
        
        e.preventDefault();
        
        const { login } = formData.current;
        
        if (resetPasswordEmail) { 
            setLoading(true);
            const reset = await resetPasswordEmail(login);
            if (reset.isOk === true) {
                setNotification({isVisible: true, message: reset.message, type: "success"});
                setHasReset(true);
            }
            else {
                setNotification({isVisible: true, message: reset.message,  type: "error"});
            }
            setLoading(false);
        }

    }, [resetPasswordEmail]);

    //const appConf = useGlobalContext(ConfContext);
    

    return (<form className={'reset-password-form'} onSubmit={onSubmit}>
        <Form formData={formData.current} disabled={loading}>
            <Item
                dataField={'login'}
                editorType={'dxTextBox'}
                
                editorOptions={loginEditorOptions}
            >
                <RequiredRule message="L'adresse e-mail est requise" />
                <EmailRule message="L'adresse e-mail n'est pas valide" />
                <Label visible={false} />
            </Item>
            
            
            <ButtonItem>
                <ButtonOptions
                    width={'100%'}
                    type={'default'}
                    useSubmitBehavior={true}
                    disabled={hasReset}
                >
                    <span className="dx-button-text">
                        { loading ? <LoadIndicator width={'24px'} height={'24px'} visible={true} /> : hasReset ? 'Email envoyé' : 'Réinitialiser' }
                    </span>
                </ButtonOptions>
            </ButtonItem>
            <Item>
                <div className={'link'}>
                    <Link to={'/login'}>Retour à la connexion</Link>
                </div>
            </Item>
        </Form>
    </form>);
}

const loginEditorOptions = { stylingMode: 'filled', placeholder: 'Adresse e-mail', mode: 'text' };
