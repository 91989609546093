import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import FullNavigationMenu from '../../components/full-navigation-menu/full-navigation-menu';

import './home.scss';


export default function Home () {

	const appMode = localStorage.getItem('oasisWebMode') || 'default';

	const history = useHistory();

	const onNavigationChanged = useCallback(({ itemData: { path } }) => {
		history.push(path);
	}, [history]);

	return (
		
		appMode !== 'scanette' ? 
			<React.Fragment>
				<h2 className={'content-block'}>Accueil</h2>
				<div className={'content-block'}>
					<div className={'dx-card responsive-paddings'}>
						<p>Bienvenue chez <b>Silicom</b>, vous trouvez ici vos décomptes de prestations et soldes de pack d’heures.</p>
						<p>En cas de questions vous pouvez contacter votre Responsable Client ou le <a href="https://silicomgroup.atlassian.net/wiki/spaces/SGP/pages/1352204370/Service+Desk" target="_blank" rel="noreferrer">Support</a></p>
					</div>
				</div>
			</React.Fragment> : <React.Fragment>
				<div className={'list-container'}>
					<FullNavigationMenu onNavigationChanged={onNavigationChanged} />
				</div>
			</React.Fragment>
	);
};