import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import TreeView from 'devextreme-react/tree-view';
import * as events from 'devextreme/events';

import { useAuth } from '../../contexts/auth';
import { navigation } from '../../app-navigation';
import { useNavigation } from '../../contexts/navigation';
import { useScreenSize } from '../../utils/media-query';

import './side-navigation-menu.scss';

export default function SideNavigationMenu (props: any) {
	const { user} = useAuth();

	const {
		children,
		selectedItemChanged,
		openMenu,
		compactMode,
		onMenuReady
	} = props;

	const { isLarge } = useScreenSize();
	const items = useMemo(
		() => navigation.map((item: any) => (
			{ 
				...item, 
				expanded: isLarge, 
				items: item.items?.filter((subItem: any) => (subItem.type.includes('default') && (user !== null && subItem.access.includes(user.AuthorizationType)))) 
			})).filter((item: any) => (item.type.includes('default') && (user !== null && item.access.includes(user.AuthorizationType)))),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const { navigationData: { currentPath } } = useNavigation();

	const treeViewRef = useRef<TreeView>(null);
	const wrapperRef = useRef();
	const getWrapperRef = useCallback((element) => {
		const prevElement = wrapperRef.current;
		if (prevElement) {
			events.off(prevElement, 'dxclick');
		}

		wrapperRef.current = element;
		events.on(element, 'dxclick', (e: any) => {
			openMenu(e);
		});
	}, [openMenu]);

	useEffect(() => {
		const treeView = treeViewRef.current && treeViewRef.current.instance;
		if (!treeView) {
			return;
		}

		if (currentPath !== undefined) {
			treeView.selectItem(currentPath);
			treeView.expandItem(currentPath);
		}

		if (compactMode) {
			treeView.collapseAll();
		}
	}, [currentPath, compactMode]);

	return (
		<div className={'dx-swatch-additional side-navigation-menu'} ref={getWrapperRef}>
			{children}

			<div className={'menu-container'}>
				<TreeView
					ref={treeViewRef}
					items={items}
					keyExpr={'path'}
					selectionMode={'single'}
					focusStateEnabled={false}
					expandEvent={'click'}
					onItemClick={selectedItemChanged}
					onContentReady={onMenuReady}
					width={'100%'}
				/>
			</div>
		</div>
	);
}
