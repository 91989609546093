export async function signIn(apiUrl: string, login: string, password: string, rememberMe: boolean) {

	try {

		const authInfos = await fetch(`${apiUrl}Oauth/token`, {
			headers: {
				'Accept': '*/*',
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			method: 'POST',
			body: new URLSearchParams({'username': login, 'password': password, 'grant_type': 'password'})
		}).then(async (res) => {

			if (res.ok) {
				return await res.json();
			}

			console.log("Auth failed");
			return false;
			
		}).catch((err) => {
			console.log("Oasis unreachable");
			console.log(err);
			return false;
		});

		console.log(authInfos);

		if (authInfos !== false) {
			if (rememberMe) {
				if (sessionStorage.getItem('oasisWebUser') !== null) {
					sessionStorage.removeItem('oasisWebUser');
				}
				localStorage.setItem('oasisWebUser', authInfos.access_token);
			}
			else {
				if (localStorage.getItem('oasisWebUser') !== null) {
					localStorage.removeItem('oasisWebUser');
				}
				sessionStorage.setItem('oasisWebUser', authInfos.access_token);
			}

			return {
				isOk: true,
				//data: defaultUser
			};
		}
		
		return {
			isOk: false,
			message: "Echec de l'authentification"
		};
	}
	catch {

		return {
			isOk: false,
			message: "Impossible de se connecter au serveur"
		};
	}
}

export function signOut() {

	try {
		// Send request
		localStorage.removeItem('oasisWebUser');
		sessionStorage.removeItem('oasisWebUser');

		return {
			isOk: true
		};
	}
	catch {
		return {
			isOk: false,
			message: "Logout failed"
		};
	}
}

export async function getUser(apiUrl: string) {
	try {
		// Send request

		const token: string | null = localStorage.getItem('oasisWebUser') || sessionStorage.getItem('oasisWebUser');

		if (token) {

			const user = await fetch(`${apiUrl}GetAuthorizationDetails`, {
				headers: new Headers({
					Authorization: 'Bearer ' + token
				}),
				method: 'GET',
				mode: 'cors'
			}).then(async (res) => {

				if (res.ok) {
					return await res.json();
				}

				console.log("Get user failed");

				return false;
				
			}).catch((err) => {
				console.log("Oasis unreachable");
				console.log(err);

				if (sessionStorage.getItem('oasisWebUser') !== null) {
					sessionStorage.removeItem('oasisWebUser');
				}
				if (localStorage.getItem('oasisWebUser') !== null) {
					localStorage.removeItem('oasisWebUser');
				}
				
				return false;
			});

			if (user !== false) {
				
				if (user.AuthorizationType === 'User') {
					const userInfo = await fetch(`${apiUrl}GetAuthorizedUser?$expand=ChildGN_USERS_DEFAUTS`, {
						headers: new Headers({
							Authorization: 'Bearer ' + token
						}),
						method: 'GET',
						mode: 'cors'
					}).then(async (res) => {
						if (res.ok) {
							return await res.json();
						}
		
						console.log("Get user infos failed");
		
						return false;
						
					}).catch((err) => {
						console.log("Oasis unreachable");
						console.log(err);
						
						return false;
					});

					if (userInfo !== false && Array.isArray(userInfo.ChildGN_USERS_DEFAUTS) && userInfo.ChildGN_USERS_DEFAUTS.length) {

						const userDefaultIndex = userInfo.ChildGN_USERS_DEFAUTS.findIndex((userDefault: any) => userDefault.CODE_SOCIETE === userInfo.SOCIETE_DEFAUT);
						if (userDefaultIndex !== -1) {
						
							return {
								isOk: true,
								data: {
									...user, 
									GnUser: {
										...userInfo,
										...userInfo.ChildGN_USERS_DEFAUTS[userDefaultIndex]
									},
									avatarUrl: 'https://silicom.group/assets/images/icon/android-icon-192x192.png'}
							};
						}
						
					}
				}
				else if (user.AuthorizationType === 'Person') {
					const personInfo = await fetch(`${apiUrl}GetAuthorizedPerson`, {
						headers: new Headers({
							Authorization: 'Bearer ' + token
						}),
						method: 'GET',
						mode: 'cors'
					}).then(async (res) => {
						if (res.ok) {
							return await res.json();
						}
		
						console.log("Get person infos failed");
		
						return false;
						
					}).catch((err) => {
						console.log("Oasis unreachable");
						console.log(err);
						
						return false;
					});

					if (personInfo !== false) {
						return {
							isOk: true,
							data: {
								...user, 
								GnPersonnes: personInfo,
								avatarUrl: 'https://silicom.group/assets/images/icon/android-icon-192x192.png'}
						};
					}
				}
				
				return {
					isOk: false,
					message: "Utilisateur inconnu"
				};
			}
		
		}

		return {
			isOk: false,
			message: "Pas de token configuré"
		};
	}
	catch {
		return {
			isOk: false,
			message: "Impossible de se connecter au serveur"
		};
	}
}

/*export async function createAccount(login: string, password: string) {
	try {
		// Send request
		console.log(login, password);

		return {
			isOk: true
		};
	}
	catch {
		return {
			isOk: false,
			message: "Failed to create account"
		};
	}
}*/

export async function changePassword(apiUrl: string, password: string, token: string) {
	try {
		
		const changeInfos = await fetch(`${apiUrl}SetNewPassword`, {
			headers: {
				'Authorization': 'Bearer ' + token,
				'Accept': '*/*',
				'Content-Type': 'application/json'
			},
			method: 'POST',
			body: JSON.stringify({'newPassword': password})
		}).then(async (res) => {

			return res;
			
		}).catch((err) => {
			return err;
		});

		
		if (changeInfos.ok) {
			return {
				isOk: true,
				message: "Mot de passe modifié"
			};
		}
		
		return {
			isOk: false,
			message: changeInfos.status === 409 ? 'Le mot de passe ne correspond pas aux critères' : 'Une erreur est survenue lors de la modification du mot de passe'
		};
	}
	catch(err) {
		console.log(err);
		return {
			isOk: false,
			message: "Impossible de se connecter au serveur"
		};
	}
}

export async function resetPassword(apiUrl: string, login: string) {
	try {

		const resetInfos = await fetch(`${apiUrl}Oauth/resetpassword`, {
			headers: {
				'Accept': '*/*',
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			method: 'POST',
			body: new URLSearchParams({'username': login, 'grant_type': 'password'})
		}).then(async (res) => {
			return res.json();
			/*if (res.ok) {
				return await res.json();
			}

			console.log("reset failed");
			return false;*/
			
		}).catch((err) => {
			console.log("Oasis unreachable");
			console.log(err);
			return false;
		});
		
		if (!('error' in resetInfos)) {
			return {
				isOk: true,
				message: "Email envoyé"
			};
		}
		
		return {
			isOk: false,
			message: resetInfos.error_description
		};
	}
	catch {
		return {
			isOk: false,
			message: "Impossible de se connecter au serveur"
		};
	}
}
