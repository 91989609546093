import { useState, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Form, {
    Item,
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule
} from 'devextreme-react/form';
import SelectBox from 'devextreme-react/select-box';
import LoadIndicator from 'devextreme-react/load-indicator';

import { useAuth } from '../../contexts/auth';
import { useConf } from '../../contexts/conf';

import './login-form.scss';

export default function LoginForm (props: {appMode: string, setAppMode: any, setNotification: any}) {


    const { appMode, setAppMode, setNotification } = props;
    
    const { logIn } = useAuth();
    const { conf } = useConf();
	
    const [loading, setLoading] = useState(false);
    const formData = useRef({ login: '', password: '', rememberMe: true });
    

    const onSubmit = useCallback(async (e) => {
        
        e.preventDefault();
        
        const { login, password, rememberMe } = formData.current;
        
        if (logIn) { 
            setLoading(true);
            const userLog = await logIn(login, password, rememberMe, setLoading, setNotification);

            if (userLog.isOk !== true) {
                setNotification({isVisible: true, message: userLog.message, type: "error"});
            }
        }

    }, [logIn]);
    

    return (<form className={'login-form'} onSubmit={onSubmit}>
        <Form formData={formData.current} disabled={loading}>
            <Item
                dataField={'login'}
                editorType={'dxTextBox'}
                editorOptions={loginEditorOptions}
            >
                <RequiredRule message="Le login est requis" />
                <Label visible={false} />
            </Item>
            <Item
                dataField={'password'}
                editorType={'dxTextBox'}
                editorOptions={passwordEditorOptions}
            >
                <RequiredRule message="Le mot de passe est requis" />
                <Label visible={false} />
            </Item>
            
            {conf.modes.length > 1 ? <Item>
                <SelectBox 
                    placeholder="Mode" 
                    displayExpr="name" 
                    valueExpr="id" 
                    defaultValue={appMode || 'default'} 
                    onValueChanged={e => setAppMode(e.value)} 
                    items={[
                        {id: 'default', name: 'Desktop'},
                        {id: 'scanette', name: 'Scanette'}
                    ].filter((item: {id: string, name: string}) => (item && conf.modes.includes(item.id)))}
                />
            </Item> : null}
            {/*<Item
                dataField={'rememberMe'}
                editorType={'dxCheckBox'}
                editorOptions={rememberMeEditorOptions}
            >
                <Label visible={false} />
            </Item>*/}
            
            <ButtonItem>
                <ButtonOptions
                    width={'100%'}
                    type={'default'}
                    useSubmitBehavior={true}
                    >
                    <span className="dx-button-text">
                        { loading ? <LoadIndicator width={'24px'} height={'24px'} visible={true} /> : 'Connexion' }
                    </span>
                </ButtonOptions>
            </ButtonItem>
            <Item>
                <div className={'link'}>
                    <Link to={'/reset-password'}>Mot de passe oublié</Link>
                </div>
            </Item>
            <Item>
                <div>
                    Vous vous connectez pour la première fois et vous n’avez pas vos accès ?<br />Ce lien vous explique les étapes à suivre<br />
                    <a href="https://silicomgroup.atlassian.net/wiki/spaces/SGP/pages/1785266293/Obtenez+facilement+vos+acc+s+OasisWeb" target="_blank" rel="noreferrer">Obtenez facilement vos accès OasisWeb</a>
                </div>
            </Item>
        </Form>
    </form>);
}
const loginEditorOptions = { stylingMode: 'filled', placeholder: 'Login', mode: 'text' };
const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Password', mode: 'password' };
const rememberMeEditorOptions = { text: 'Se souvenir de moi', elementAttr: { class: 'form-text' } };
