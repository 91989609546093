export const navigation = [
    {
        text: 'Accueil',
        path: '/home',
        icon: 'home',
        type: ['default'],
        access: ['Person', 'User']
    },
    {
        text: 'Scanner',
        icon: 'photo',
        type: ['scanette'],
        access: ['User'],
        items: [
            {
                text: 'Création doc. vente',
                path: '/creation-doc-vente',
                type: ['scanette'],
                access: ['User']
            },
            {
                text: 'Sortie palettes',
                path: '/sortie-palettes',
                type: ['scanette'],
                access: ['User']
            }
        ]
    },
    {
        text: 'Mes activités',
        icon: 'folder',
        type: ['default'],
        access: ['Person', 'User'],
        items: [
            {
                text: 'Prestations',
                path: '/documents',
                type: ['default'],
                access: ['Person', 'User']
            },
            {
                text: 'Packs d\'heures',
                path: '/hours-packs',
                type: ['default'],
                access: ['Person', 'User']
            }
        ]
    }
];