import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const UpdateSW = () => {
	const [showReload, setShowReload] = useState(false);
	const [waitingWorker, setWaitingWorker] = useState(Object);
	
	const onSWUpdate = (registration: any) => {
		setShowReload(true);
		setWaitingWorker(registration.waiting);
	};
	
	const reloadPage = () => {
		if (waitingWorker !== null) {
			waitingWorker.postMessage({ type: 'SKIP_WAITING' });
		}
		setShowReload(false);
		window.location.reload();
	};
	
	useEffect(() => {
		serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
	}, []);

	return (
		showReload ? 
		<div style={{position: 'fixed', width: '400px', bottom: 0, left: 'calc(50% - 200px)', padding: '10px', textAlign: 'center', backgroundColor: '#fff', color: 'rgba(0, 0, 0, 0.87)', boxShadow: '0 0 12px rgba(0, 0, 0, 0.4)', zIndex: 1000}}>
			<p style={{marginTop: 0}}>Une mise à jour de l'application est disponible</p>
			<button className="btn" onClick={reloadPage}>Mettre à jour</button>
		</div> : null
	);
}

ReactDOM.render(
  <React.StrictMode>
    <App />
    <UpdateSW />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
