import { withNavigationWatcher } from './contexts/navigation';

import HomePage from './pages/home/home';
import DocumentsPage from './pages/documents/documents';
import HoursPacksPage from './pages/hours-packs/hours-packs';
import CreationDocVentePage from './pages/creation-doc-vente/creation-doc-vente';
import SortiePalettesPage from './pages/YI-sortie-palettes/YI-sortie-palettes';

const routes = [
	{
		path: '/home',
		component: HomePage
	},
	{
		path: '/documents',
		component: DocumentsPage
	},
	{
		path: '/hours-packs',
		component: HoursPacksPage
	},
	{
		path: '/creation-doc-vente',
		component: CreationDocVentePage
	},
	{
		path: '/sortie-palettes',
		component: SortiePalettesPage
	}
];

export default routes.map(route => {
	return {
		...route,
		component: withNavigationWatcher(route.component)
	};
});
