import {Fields} from "./Fields";

export interface IVE_LIGNE_DOCUM {
    CODE_SOCIETE: number;
    TY_TRAIT_DOC_VENTE: number;
    NO_DOCUMENT: number;
    NO_BON_LIVRE_RECAP: number;
    NO_LIGNE_DOCUMENT: number;
    TY_SAISIE_DOC: number;
    NO_TIERS: number;
    CD_COMMANDE_FACTURE: number;
    NO_POS_DOCUMENT: number;
    REFERENCE_ARTICLE_A: string;
    CD_FILIALE: string;
    CD_DEPOT: string;
    INTEGRATION_FILIALE: number;
    INTEGRATION_ARTICLE: number;
    REF_ARTICLE_CLIENT: string;
    CODE_EAN_CLIENT: number;
    NO_CENTRE: string;
    REFERENCE_PROJET: string;
    STRUCTURE_PROJET: string;
    QTE_UNITE_VENTE: number;
    QTE_DOCUMENT_U_STOCK: number;
    QTE_PROPOSEE_U_STOCK: number;
    QTE_LIVREE_U_STOCK: number;
    QTE_FACTUREE_U_STOCK: number;
    ANNULATION_RELIQUAT: boolean;
    QTE_ANNULEE_U_STOCK: number;
    QTE_EMBALLAGE_US: number;
    UNITE_VENTE: string;
    UNITE_STOCK: string;
    FACT_CONV_ACH_VTE: number;
    PRIX_VENTE_UNITAIRE_HT: number;
    PRIX_VENTE_UNITAIRE_TTC: number;
    PRIX_VENTE_PUBLIC: number;
    PRIX_DEDUC_GROUPEE: number;
    UNITE_PRIX: number;
    REMISABLE: boolean;
    PROVENANCE_PRIX: number;
    NO_ACTION_TARIF: number;
    PRIX_THEORIQUE_CALCUL: number;
    POIDS_UNIT_ARTICLE_NET: number;
    PAR_U_STOCK_POIDS_NET: number;
    POIDS_UNIT_ARTICLE_BRUT: number;
    PAR_N_U_STOCK_POIDS_BRUT: number;
    RESERVE_QTE_EMP_SPC: number;
    QUANTITE_VENDUE_US: number;
    TOTAL_LIGNE: number;
    TOTAL_LIGNE_TTC: number;
    TOTAL_LIGNE_HT: number;
    TOTAL_REMISE_BASE: number;
    TOTAL_REMISE_TARIFAIRE: number;
    TOTAL_REMISE_QTE: number;
    TOTAL_TAXE_LIGNE: number;
    REMISE_BASE_LIGNE: number;
    REMISE_TARIF_LIGNE: number;
    REMISE_QTE_LIGNE: number;
    COMMISSION_REPR: number;
    REMISE_GROUPEE: number;
    CD_TVA_TAUX: number;
    CD_TVA_TYPE: number;
    DATE_LIVRAISON: number;
    DATE_LIVR_CONFIRMEE: number;
    DATE_ORDRE_OFOA: number;
    RESERVE_DATE_1: number;
    SUR_APPEL: boolean;
    CRITERE_DOCUMENT: string;
    ORDRE_FABR_GENERE: boolean;
    REMARQUE_1: string;
    REMARQUE_2: string;
    TY_TRAIT_DOC_ACHAT: number;
    NO_DOCUMENT_ACHAT: number;
    NO_LIGNE_DOCUMENT_ACHAT: number;
    TRI_EDITION_LIGNE: number;
    RUPTURE_EDITION: number;
    ST_CONFIRME_LIGNE: number;
    ST_PROPOSE_LIGNE: number;
    ST_LIVRE_LIGNE: number;
    ST_FACTURE_LIGNE: number;
    LIGNE_GENEREE_AUT: number;
    ID: number;
    DLU: Date;
    NE_PAS_IMPRIMER: boolean;
    ID_TYPE_ART_LIE: number;
    IGNORER_ACHAT_TRANSFERT: boolean;
    FLAG_INSTALLATION_SAV: boolean;
}

export const VE_LIGNE_DOCUM =  Fields<IVE_LIGNE_DOCUM>();