import React from 'react';

import Bullet, {
    Font, Margin, Size, Tooltip,
} from 'devextreme-react/bullet';
import {IPackHeures} from "../../models/PackHeures";


interface ISoldeCellRenderProps {
    data: IPackHeures
}


export default ({data: packHeure}: ISoldeCellRenderProps) => {

    return (


        <Bullet
            showTarget={false}
            showZeroLevel={true}
            value={packHeure.SoldeTtc / packHeure.AccompteTtc * 100}
            startScaleValue={0}
            endScaleValue={100}
        >
            <Size width={100} height={20}/>
            <Margin top={5} bottom={0} left={5}/>
            <Tooltip
                enabled={true}
                paddingTopBottom={2}
                zIndex={5}
                customizeTooltip={customizeTooltip}
            >
                <Font size={18}/>
            </Tooltip>
        </Bullet>
    );
}

function customizeTooltip(data: any) {
    return {
        text: `${parseInt(data.value, 10)}%`,
    };
}
