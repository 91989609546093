import React, {useEffect, useRef, useState} from 'react';
import {useAuth} from '../../contexts/auth';
import {useConf} from '../../contexts/conf';
import DataSource from 'devextreme/data/data_source';
import Button from 'devextreme-react/button';
import DataGrid, {Column, Editing, Item, Lookup, Toolbar} from 'devextreme-react/data-grid';
import {IToastOptions, Toast} from 'devextreme-react/toast';
import {IST_ARTICLE, ST_ARTICLE} from '../../models/ST_ARTICLE';
import {IVE_LIGNE_DOCUM, VE_LIGNE_DOCUM} from '../../models/VE_LIGNE_DOCUM';

import './lignes-document.scss';
import {IVE_TETE_DOCUM} from "../../models/VE_TETE_DOCUM";
import Notify from 'devextreme/ui/notify';
import dxDataGrid, { EditorPreparingEvent, SavingInfo } from 'devextreme/ui/data_grid';
import { EventInfo } from 'devextreme/events';
interface IlignesDocumentProps {
    data:
        {
            data: IVE_TETE_DOCUM
        }
}

export default function LignesDocument({data: {data}} :IlignesDocumentProps) {

    const {conf, oDataStore} = useConf();
    const {user} = useAuth();

    const [toastConfig, setToastConfig] = useState<IToastOptions>({
        visible: false,
        type: 'info',
        message: ''
    });

    const hasEditData = useRef<boolean>(false);
    const setHasEditData = (edit: boolean) => {
        hasEditData.current = edit;
    }

    const scanArticleRef = useRef<string>('');
    const setScanArticle = (ref: string) => {
        scanArticleRef.current = ref;
    }

    const [scanQuantity, _setScanQuantity] = useState<number>(0);
    const scanQuantityRef = useRef<number>(scanQuantity);
    const setScanQuantity = (qty: number) => {
        scanQuantityRef.current = qty;
        _setScanQuantity(qty);
    }

    const dataGridRef = useRef<DataGrid>(null);
    let articleLookupRef = useRef<any>(null);
    let articleQtyRef = useRef<any>(null);

    let scanTxt: string = '';
    const scanRegExp = /<scan>[a-zA-Z0-9]+<\/scan>/g;

    const lastScanEan = useRef(0);
    const scanEan = useRef(0);


    const articles = {
        // @ts-ignore
        store: oDataStore.OdataArticles,
        select: [
            ST_ARTICLE.ID,
            ST_ARTICLE.CODE_SOCIETE,
            ST_ARTICLE.REFERENCE_ARTICLE_A,
            ST_ARTICLE.REFERENCE_ARTICLE_B,
            ST_ARTICLE.DESIGN_1_ARTICLE,
            ST_ARTICLE.DESIGN_2_ARTICLE,
            ST_ARTICLE.DESIGN_3_ARTICLE,
            ST_ARTICLE.DESIGN_4_ARTICLE
        ],
        filter: [
            [ST_ARTICLE.CODE_SOCIETE, '=', user?.GnUser?.CODE_SOCIETE]
        ],
        paginate: true,
        pageSize: 20
    };


    const bindScanEvents = () => {
        console.log("bindScanEvents");
        scanTxt = '';
        document.addEventListener("keypress", keyCapture);

    }
    const unbindScanEvents = () => {
        console.log("unbindScanEvents");
        scanTxt = '';
        document.removeEventListener("keypress", keyCapture);
    }

    useEffect(() => {
        window.onbeforeunload = () => {
            unbindScanEvents();

            if (hasEditData.current) {
                recalculateOrder().catch(error => Notify(error, "error"));
            }
        }

        return () => {
            window.onbeforeunload = null;
            unbindScanEvents();

            if (hasEditData.current) {
                recalculateOrder().catch(error => Notify(error, "error"));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const keyCapture = (e: KeyboardEvent) => {
        console.log(e.key);
        const target = e.target as HTMLInputElement;
        if (e.key) {

            // Blur from field
            if (e.key.toString() === '<' && target !== null && target.classList.contains('dx-texteditor-input')) {
                target.blur();
            }

            scanTxt = scanTxt + e.key.toString();

            let scanTxtMatch = scanTxt.match(scanRegExp);
            if (scanTxtMatch !== null) {
                const scanValue: number = parseInt(scanTxtMatch[0].replace(/<scan>|<\/scan>/g, ''));
                scanTxt = '';

                setScanEan(scanValue);


            }
        }
    }

    const setScanEan = (ean: number) => {
        console.log("setScanEan");
        scanEan.current = ean;


        console.log("add Article");
        dataGridRef.current?.instance.cancelEditData();

        const articleDataSource = new DataSource({
            ...articles, filter: [
                [ST_ARTICLE.CODE_SOCIETE, '=', user?.GnUser?.CODE_SOCIETE],
                ['ChildDI_EAN/any(ean:ean/CODE_EAN eq ' + scanEan.current + ')', '=', true],
            ]
        });

        articleDataSource.load().then((scannedArticle) => {
            console.log(scannedArticle);
            if (scannedArticle.length) {

                setScanArticle(scannedArticle[0].REFERENCE_ARTICLE_A);
                dataGridRef.current?.instance.addRow().then(() => {

                    const target = document.activeElement as HTMLInputElement;
                    console.log(target);
                    if (target !== null && target.classList.contains('dx-texteditor-input')) {
                        target.blur();
                    }
                });

            } else {
                setToastConfig({
                    ...toastConfig,
                    visible: true,
                    type: 'error',
                    message: 'Article non trouvé'
                });
                scanEan.current = 0;
            }
        });

        lastScanEan.current = scanEan.current;
        //}
    }

    useEffect(() => {
        console.log(scanQuantity);
        if (scanQuantity > 1) {
            dataGridRef.current?.instance.addRow().then(() => {

                const target = document.activeElement as HTMLInputElement;

                if (target !== null && target.classList.contains('dx-texteditor-input')) {
                    target.blur();
                }
            });
        }
    }, [scanQuantity]);

    const lignesDocumentsVente = new DataSource({
        // @ts-ignore
        store: oDataStore.OdataLignesDocumentsVente,
        select: [
            VE_LIGNE_DOCUM.ID,
            VE_LIGNE_DOCUM.CODE_SOCIETE,
            VE_LIGNE_DOCUM.TY_TRAIT_DOC_VENTE,
            VE_LIGNE_DOCUM.NO_DOCUMENT,
            VE_LIGNE_DOCUM.NO_BON_LIVRE_RECAP,
            VE_LIGNE_DOCUM.NO_LIGNE_DOCUMENT,
            VE_LIGNE_DOCUM.REFERENCE_ARTICLE_A,
            VE_LIGNE_DOCUM.QTE_UNITE_VENTE,
            VE_LIGNE_DOCUM.TOTAL_LIGNE_TTC,
            VE_LIGNE_DOCUM.QTE_DOCUMENT_U_STOCK
        ],
        filter: [
            [VE_LIGNE_DOCUM.CODE_SOCIETE, '=', data.CODE_SOCIETE],
            [VE_LIGNE_DOCUM.TY_TRAIT_DOC_VENTE, '=', data.TY_TRAIT_DOC_VENTE],
            [VE_LIGNE_DOCUM.NO_DOCUMENT, '=', data.NO_DOCUMENT]
        ]
    });

    const setNewLineData = (e: any) => {

        e.data.CODE_SOCIETE = data.CODE_SOCIETE;
        e.data.TY_TRAIT_DOC_VENTE = data.TY_TRAIT_DOC_VENTE;
        e.data.NO_DOCUMENT = data.NO_DOCUMENT;
        e.data.NO_LIGNE_DOCUMENT = 0;
        e.data.QTE_UNITE_VENTE = scanQuantity;
        e.data.CD_FILIALE = user?.GnUser?.CD_FILIALE;
        e.data.CD_DEPOT = user?.GnUser?.CD_DEPOT;
        e.data.QTE_UNITE_VENTE = 0;
        if (scanArticleRef.current !== '') {
            e.data.REFERENCE_ARTICLE_A = scanArticleRef.current;
        }
    }


    const articleFormat = (article: IST_ARTICLE) => {
        return article.REFERENCE_ARTICLE_A + (article.DESIGN_1_ARTICLE.length > 0 ? ' - ' + article.DESIGN_1_ARTICLE : '');
    }

    const recalculateOrder = async () => {
        console.log("recalculateOrder");

        const recalculateData = await fetch(conf.oasisApiUrl + 'OdataTetesDocumentsVente(CODE_SOCIETE=' + data.CODE_SOCIETE + ',TY_TRAIT_DOC_VENTE=' + data.TY_TRAIT_DOC_VENTE + ',NO_DOCUMENT=' + data.NO_DOCUMENT + ')/CalcDoc', {
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + (localStorage.getItem('oasisWebUser') || sessionStorage.getItem('oasisWebUser'))
            },
            method: 'POST',
            body: JSON.stringify({
                'actualiserPrix': true,
                'calculerPrixLigne0': true,
                'conserverTauxTva': false,
                'actualiserCondition': true,
                'actualiserAdresseLivr': true,
                'totalAttendu': 0,
                'editionDocument': false,
                'actualiserTournee': true
            })
        })
            .then(async (res) => {
                return res.json();
            })
            .catch((err) => {
                console.log("Oasis unreachable");
                console.log(err);

                return false;
            });

        // @ts-ignore
        return oDataStore.OdataTetesDocumentsVente.push([{
            type: "update",
            data: {TOT_LIGNES_TTC: recalculateData.TOT_LIGNES_TTC},
            key: {
                CODE_SOCIETE: data.CODE_SOCIETE,
                TY_TRAIT_DOC_VENTE: data.TY_TRAIT_DOC_VENTE,
                NO_DOCUMENT: data.NO_DOCUMENT
            }
        }]);
    }

    const onEditorPreparing = (e: EditorPreparingEvent) => {
        if(e.dataField === VE_LIGNE_DOCUM.REFERENCE_ARTICLE_A && e.parentType ==="dataRow"){
            e.editorOptions.disabled = e.row?.isNewRow ? false : true;
        }
    }

    const onEditorPrepared = (e: any) => {

        if (e.dataField === "REFERENCE_ARTICLE_A" && e.parentType === "dataRow") {
            articleLookupRef.current = e;
        }

        if (e.dataField === "QTE_UNITE_VENTE" && e.parentType === "dataRow") {
            articleQtyRef.current = e;
        }

    }

    const onEditorCanceled = () => {
        setScanArticle('');
        setScanQuantity(0);
    }

    const onSaving = (e: EventInfo<dxDataGrid<IVE_LIGNE_DOCUM, any>> & SavingInfo<IVE_LIGNE_DOCUM, any>) => {
        let i = 0;
        while (i < e.changes.length) {
            e.changes[i].data = {...e.changes[i].data, QTE_DOCUMENT_U_STOCK: 0}
            i++;
        }
    }

    function onToastHiding() {
        setToastConfig({
            ...toastConfig,
            visible: false,
        });
    }

    return (
        <React.Fragment>

            <DataGrid
                className={'dx-card wide-card'}
                ref={dataGridRef}
                dataSource={lignesDocumentsVente}
                allowColumnResizing={false}
                focusedRowEnabled={false}
                repaintChangesOnly={true}
                rowAlternationEnabled={true}
                onInitNewRow={setNewLineData}
                onSaving={onSaving}
                onInitialized={() => {
                    bindScanEvents();
                }}
                onRowInserted={() => setHasEditData(true)}
                onRowRemoved={() => setHasEditData(true)}
                onRowUpdated={() => setHasEditData(true)}
                onEditCanceled={onEditorCanceled}
                onEditorPreparing={onEditorPreparing}
                onEditorPrepared={onEditorPrepared}
            >

                <Editing
                    mode="popup"
                    allowUpdating={true}
                    allowDeleting={true}
                    allowAdding={true}
                    useIcons={true}
                    texts={{
                        saveRowChanges: "Valider",
                        confirmDeleteMessage: "Êtes-vous sûr de vouloir supprimer cet article ?",
                    }}
                />

                <Toolbar>
                    <Item location="before">
                        <Button
                            text='Ajouter un article'
                            icon='plus'
                            onClick={(e) => dataGridRef.current?.instance.addRow()} />
                    </Item>
                </Toolbar>

                <Column dataField={VE_LIGNE_DOCUM.REFERENCE_ARTICLE_A}
                        calculateDisplayValue={VE_LIGNE_DOCUM.REFERENCE_ARTICLE_A}
                        caption="Article"
                        formItem={{cssClass: "scanner_input"}}
                        minWidth={120}
                        editorOptions={{searchExpr: [ST_ARTICLE.REFERENCE_ARTICLE_A, ST_ARTICLE.DESIGN_1_ARTICLE]}}>
                    <Lookup
                        // @ts-ignore
                        dataSource={articles}
                        valueExpr={ST_ARTICLE.REFERENCE_ARTICLE_A}
                        displayExpr={articleFormat}
                        allowClearing={true}
                    />
                </Column>
                <Column dataField={VE_LIGNE_DOCUM.QTE_UNITE_VENTE} caption="Quantité" dataType="number"/>
                {/*<Column dataField={VE_LIGNE_DOCUM.TOTAL_LIGNE_TTC} caption="Prix"
                        format={{type: "currency", currency: "CHF", precision: 2}} allowEditing={false}
                        formItem={{visible: false}}/>*/}
                <Column dataField={VE_LIGNE_DOCUM.QTE_DOCUMENT_U_STOCK} dataType="number" visible={false} allowEditing={false}
                        formItem={{visible: false}}/>


            </DataGrid>

            <Toast
                visible={toastConfig.visible}
                message={toastConfig.message}
                type={toastConfig.type}
                onHiding={onToastHiding}
                displayTime={600}
            />
        </React.Fragment>
    )
}