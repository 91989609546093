import React from 'react';
import { useConf } from '../../contexts/conf';

import DataGrid, {Column, GroupPanel, Paging} from 'devextreme-react/data-grid';
import { nl2br } from '../../utils/functions';

import './prestations.scss';
import {getPrestations} from "../../odata/prestations";
import {IVE_TETE_DOCUM} from "../../models/VE_TETE_DOCUM";
import {IDocumentVentePrestation} from "../../models/DocumentVentePrestation";
import {IMA_FRAIS_COLLABORATEUR} from "../../models/MA_FRAIS_COLLABORATEUR";


interface IPrestationsProps{
	data:
		{
			data: IVE_TETE_DOCUM
		}
}

export default function Prestations ({data: {data}}: IPrestationsProps)  {

	const {conf} = useConf();

	const prestations = getPrestations(conf, data);

	const ValueHeurTrav = (e: any) => {
		if (e.NombreHeureTrav < e.NombreHeureFact) {
			return e.NombreHeureFact;
		}
		return e.NombreHeureTrav;
	}

	return (
		<React.Fragment>
			<h3>Prestations</h3>
            <DataGrid 
                className={'dx-card wide-card'}
                dataSource={prestations} 
                allowColumnResizing={true}
                columnAutoWidth={true}
                focusedRowEnabled={true}
                rowAlternationEnabled={true}
				columnHidingEnabled={true}
				width={'auto'}>

				<Paging enabled={false} />
				<GroupPanel visible={true} />

				<Column dataField="Projet" caption="Projet" width={150} groupIndex={0} />
				<Column dataField="SousProjet" caption="Sous projet" width={150} groupIndex={1} />
				<Column dataField="Collaborateur" caption="Collaborateur" width={150} />
				<Column dataField="Dte" caption="Date" dataType="date" format="dd.MM.yyyy" width={90} />
				<Column dataField="DescriptionClient" caption="Description" /*customizeText={(cellInfo: any) => cropText(cellInfo.value, 50)}*/ encodeHtml={true} width={'50%'} />
				<Column dataField="NombreHeureTrav" caption="Effectué" width={65} calculateCellValue={ValueHeurTrav} format="##0.00" />
				<Column dataField="NombreHeureFact" caption="Facturé" width={65} format="##0.00" />
				<Column dataField="PrixUnitaire" caption="Tarif" format={{type: "currency", precision: 2}} width={110}/>
				 {/*<Column 
					calculateDisplayValue={(prestation: IDocumentVentePrestation) => 
						prestation.FraisCollaborateur.filter((frais: IMA_FRAIS_COLLABORATEUR) => frais.CODE_CATEGORIE === 16).map((frais: IMA_FRAIS_COLLABORATEUR) => frais.MONTANT_TTC).reduce((a: number, b: number) => a + b)
					} 
					caption="Frais déplacement" 
					format={{type: "currency", precision: 2}} 
					width={110} />*/}
				<Column 
					calculateDisplayValue={(prestation: IDocumentVentePrestation): number => {
						const fraisDeplacement = prestation.FraisCollaborateur.filter((frais: IMA_FRAIS_COLLABORATEUR) => frais.CODE_CATEGORIE === 16).map((frais: IMA_FRAIS_COLLABORATEUR) => frais.MONTANT_TTC);
						return fraisDeplacement.length ? fraisDeplacement.reduce((a: number, b: number) => a + b) : 0;
					}} 
					caption="Frais déplacement" 
					format={{type: "currency", precision: 2}} 
					width={110} 
					alignment='right'/>
				<Column dataField="Prix" caption="Montant HT" format={{type: "currency", precision: 2}} width={110} />
				<Column dataField="DescriptionClient" name="Description longue" caption="Description" hidingPriority={0} customizeText={(cellInfo: any) => nl2br(cellInfo.value)} encodeHtml={false} />

            </DataGrid>
		</React.Fragment>
	)
}