import React, { useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { UnauthenticatedLayout } from './layouts';

import LoginForm from './components/login-form/login-form';
import ResetPasswordForm from './components/reset-password-form/reset-password-form';
import ChangePasswordForm from './components/change-password-form/change-password-form';

import { Toast } from 'devextreme-react/toast';

export default function NotAuthenticatedContent (props: {appMode: string, setAppMode: any}) {

    interface NotificationOptions {
        isVisible: boolean;
        message: string;
        type: "error" | "custom" | "info" | "success" | "warning" | undefined
    }

    const [notification, setNotification] = useState<NotificationOptions>({ isVisible: false, message: '', type: undefined});

    const hideNotification = () => {
        setNotification({...notification, isVisible: false})
    }

    return (
        <>
            <Switch>

                <Route exact path='/login' >
                    <UnauthenticatedLayout title="Connexion">
                        <LoginForm appMode={props.appMode} setAppMode={props.setAppMode} setNotification={setNotification} />
                    </UnauthenticatedLayout>
                </Route>

                <Route exact path='/reset-password' >
                    <UnauthenticatedLayout
                        title="Réinitialiser le mot de passe"
                        description="Veuillez entrer l'adresse e-mail de votre compte, vous recevrez un e-mail contenant un lien permettant de réinitialiser votre mot de passe."
                    >
                        <ResetPasswordForm setNotification={setNotification} />
                    </UnauthenticatedLayout>
                </Route>

                <Route exact path='/change-password' >
                    <UnauthenticatedLayout title="Changer le mot de passe">
                        <ChangePasswordForm setNotification={setNotification} />
                    </UnauthenticatedLayout>
                </Route>

                <Redirect to={'/login'} />

            </Switch>

            <Toast 
                visible={notification.isVisible} 
                message={notification.message}
                type={notification.type}
                onHiding={hideNotification}
                displayTime={2600} 
            />
        </>
    );
}