import { useEffect, useState } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import globalConfig from "devextreme/core/config"
import { NavigationProvider } from './contexts/navigation';
import { AuthProvider, useAuth } from './contexts/auth';
import { ConfProvider, useConf } from './contexts/conf';
import { useScreenSizeClass } from './utils/media-query';

import LoadPanel from 'devextreme-react/load-panel';
import frMessages from "devextreme/localization/messages/fr.json";
import { locale, loadMessages } from "devextreme/localization";

import Content from './Content';
import NotAuthenticatedContent from './NotAuthenticatedContent';

import 'devextreme/dist/css/dx.light.css';
import './dx-styles.scss';


function App() {

	const { conf } = useConf();
	const { user, loading } = useAuth();
	const screenSizeClass = useScreenSizeClass();
	
	const [ appMode, setAppMode ] = useState(localStorage.getItem('oasisWebMode') || 'default');

	useEffect(() => {
		document.title = conf.applicationName;
		globalConfig({ defaultCurrency: conf.defaultCurrency });
	}, [conf]);

	useEffect(() => {
		if (conf.modes.length && appMode) {
			
			if (conf.modes.includes(appMode)) {
				if (localStorage.getItem('oasisWebMode') !== appMode) {
					localStorage.setItem('oasisWebMode', appMode);
				}
			}
			else {
				setAppMode(conf.modes[0]);
			}
		}
		
    }, [appMode, conf]);

	return <Router basename={conf.basename}>
		
			<NavigationProvider>
				<div className={`app ${screenSizeClass}`}>
					{loading ?  <LoadPanel visible={true} /> : (user ? <Content appMode={appMode} /> : <NotAuthenticatedContent appMode={appMode} setAppMode={setAppMode} />)}
				</div>
			</NavigationProvider>
	</Router>;

}

function Root() {

	loadMessages(frMessages);
	locale(navigator.language);

	return <ConfProvider>
		<AuthProvider>
			<App />
		</AuthProvider>
	</ConfProvider>;
}

export default Root;
