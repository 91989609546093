import { useEffect, useState } from "react";
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import Toast, { IToastOptions } from "devextreme-react/toast";
import { useAuth } from "../../contexts/auth";
import { useConf } from "../../contexts/conf";
import { YI_PALETTE, IYI_PALETTE } from "../../models/YI_PALETTES";
import DataGrid, { Column } from "devextreme-react/data-grid";
import DataSource from 'devextreme/data/data_source';
import ScrollView from "devextreme-react/scroll-view";


export default function SortiePalettes() {

    const {conf, oDataStore} = useConf();
    const {user} = useAuth();

    const [currentPalette, setCurrentPalette] = useState<IYI_PALETTE | null>(null);
    const [popupVisible, setPopupVisible] = useState(false);

    const [toastConfig, setToastConfig] = useState<IToastOptions>({
        displayTime: 5000,
        visible: false,
        type: 'info',
        message: '',
        closeOnClick: true
    });

    let scanTxt: string = '';
    const scanRegExp = /<scan>[a-zA-Z0-9]+<\/scan>/g;

    const palettes = new DataSource({
        // @ts-ignore
        store: oDataStore.OdataPalettes,
        select: [
            YI_PALETTE.ID,
            YI_PALETTE.REFERENCE_ARTICLE_A,
            YI_PALETTE.QUANTITE,
            YI_PALETTE.DATE_ENTREE,
            YI_PALETTE.DATE_SORTIE,
            YI_PALETTE.STATUT
        ],
        filter: [
            [YI_PALETTE.CODE_SOCIETE, '=', user?.GnUser?.CODE_SOCIETE],
            /*[YI_PALETTE.STATUT, '=', 'Entrée']*/
        ],
        sort: [
            { selector: YI_PALETTE.DATE_SORTIE, desc: true },
            { selector: YI_PALETTE.DATE_ENTREE, desc: true }
        ],
        expand: [YI_PALETTE.ParentST_ARTICLE],
        paginate: true,
        pageSize: 20
    });

    useEffect(() => {
        document.addEventListener("keypress", keyCapture);

        return () => {
            document.removeEventListener("keypress", keyCapture);
        }
    }, []);

    useEffect(() => {
        if (currentPalette !== null && !popupVisible) {
            setPopupVisible(true);
        }
        else if (currentPalette === null && popupVisible) {
            setPopupVisible(false);
        }
    }, [currentPalette]);
    
    const keyCapture = (e: KeyboardEvent) => {
        if (e.key) {

            scanTxt = scanTxt + e.key.toString();
            let scanTxtMatch = scanTxt.match(scanRegExp);
            if (scanTxtMatch !== null) {
                const scanValue: number = parseInt(scanTxtMatch[0].replace(/<scan>|<\/scan>/g, ''));
                scanTxt = '';
                takeOutValidation(scanValue);
            }
        }
    }

    const takeOutValidation = async (id: number) => {
        try {
            // @ts-ignore
            const palette = await oDataStore.OdataPalettes.byKey(id, {expand: YI_PALETTE.ParentST_ARTICLE});
            if (palette.ID !== undefined) {
                setCurrentPalette(palette);
            }
            else {
                throw new Error('Palette non trouvée');
            }
            
        }
        catch (err) {
            setToastConfig({
                ...toastConfig,
                visible: true,
                type: 'error',
                message: `${err}`
            });
        }
    }

    const takeOut = async (id: number) => {
        try {
            // @ts-ignore
            await oDataStore.OdataPalettes.update(id, {[YI_PALETTE.STATUT]: "2", [YI_PALETTE.DATE_SORTIE]: new Date().toISOString()});
            setToastConfig({
                ...toastConfig,
                visible: true,
                type: 'success',
                message: `Palette #${id} sortie`
            });

            setCurrentPalette(null);
        }
        catch (err) {
            setToastConfig({
                ...toastConfig,
                visible: true,
                type: 'error',
                message: `Erreur\n${err}`
            });
        }
        
        
    }

    return <div>
        <p style={{textAlign: 'center'}}><strong>Scannez une palette</strong></p>

        <DataGrid
            dataSource={palettes}
            rowAlternationEnabled={true}
            repaintChangesOnly={true}
            columnAutoWidth={true}
        >
            <Column dataField={YI_PALETTE.ID} caption="ID" hidingPriority={2} />
            <Column dataField={YI_PALETTE.REFERENCE_ARTICLE_A} caption="Référence" hidingPriority={1} />
            <Column calculateDisplayValue={(palette: IYI_PALETTE) => palette.ParentST_ARTICLE.DESIGN_1_ARTICLE} caption="Article" allowSorting={false}></Column>
            <Column dataField={YI_PALETTE.QUANTITE} caption="Quantité" allowSorting={false}/>
            <Column dataField={YI_PALETTE.STATUT} caption="Statut" allowSorting={false}/>
            <Column dataField={YI_PALETTE.DATE_ENTREE} caption="Date d'entrée" dataType='date' format='dd.MM.yyyy HH:mm:ss' hidingPriority={4} allowSorting={false}/>
            <Column dataField={YI_PALETTE.DATE_SORTIE} caption="Date de sortie" dataType='date' format='dd.MM.yyyy HH:mm:ss' hidingPriority={3} allowSorting={false}/>
        </DataGrid>

        <Popup
            title={`Palette #${currentPalette?.ID}`}
            width={300}
            visible={popupVisible}
            container=".dx-viewport"
            onHiding={() => {setCurrentPalette(null)}}
        >
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="before"
                options={{
                    text: 'Annuler',
                    onClick: () => {setCurrentPalette(null)},
                }}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={{
                    text: 'Valider',
                    onClick: () => currentPalette ? takeOut(currentPalette.ID) : null,
                }}
            />
            <ScrollView width='100%' height='100%'>
                <p><strong>Article</strong><br/>{currentPalette?.ParentST_ARTICLE.DESIGN_1_ARTICLE}</p>
                <p><strong>Réf. article</strong><br/>{currentPalette?.REFERENCE_ARTICLE_A}</p>
                <p><strong>Quantité</strong><br/>{currentPalette?.QUANTITE}</p>
                <p><strong>Date entrée</strong><br/>{currentPalette?.DATE_ENTREE.toLocaleString()}</p>
                <p><strong>Statut</strong><br/>{currentPalette?.STATUT}</p>
            </ScrollView>
            
        </Popup>

        <Toast
            visible={toastConfig.visible}
            message={toastConfig.message}
            type={toastConfig.type}
            displayTime={toastConfig.displayTime}
            closeOnClick={toastConfig.closeOnClick}
            onHiding={() => setToastConfig({...toastConfig, visible: false})}
        />
    </div>;
};