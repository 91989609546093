import {Fields} from "./Fields";

export interface IVE_TETE_DOCUM {
    CODE_SOCIETE: number;
    TY_TRAIT_DOC_VENTE: number;
    NO_DOCUMENT: number;
    TY_SAISIE_DOC: number;
    TY_COMPTE_TIERS: number;
    NO_TIERS: number;
    NO_TIERS_PRINCIPAL: number;
    NOM_ABREGE: string;
    NO_EXERCICE: number;
    DATE_DOCUMENT: number;
    DATE_DOCUMENTDateTime: Date;
    DATE_LIVRAISON: number;
    DATE_LIVR_CONFIRMEE: number;
    NO_ADR_LIVRAISON: number;
    NO_ADR_TRANSITAIRE: number;
    DATE_DEPART_USINE: number;
    DATE_PREPARATION: number;
    CODE_REPRESENTANT: string;
    TITRE_MONSIEUR: string;
    RAISON_SOCIALE_NOM: string;
    BRANCHE_PROFESSION: string;
    ADRESSE_1: string;
    ADRESSE_2: string;
    RUE_NUMERO: string;
    CASE_POSTALE: string;
    CODE_POSTAL: string;
    VILLE: string;
    CD_PAYS_CH: number;
    CD_COMMANDE_FACTURE: number;
    CD_LIVRAISON: number;
    CODE_LANGUE: number;
    CODE_FORMULAIRE: number;
    CD_DEVISE: string;
    UNITE_CHANGE: number;
    COURS_MONNAIE_VENTE: number;
    CD_TRAIT_DEVISE: number;
    CD_TVA_TIERS: number;
    CODE_COND_PMT: number;
    CODE_PAIEMENT: number;
    CD_TARIF: number;
    CD_INTEGRATION: number;
    NO_CENTRE: string;
    REFERENCE_PROJET: string;
    STRUCTURE_PROJET: string;
    CRITERE_DOCUMENT: string;
    TY_CONTACT: string;
    NO_TOURNEE: number;
    NIO_TOURNEE_SUITE: number;
    CODE_PRIORITE: number;
    CD_COMMISSION: number;
    NOTE_CREDIT_AVEC_SANS: boolean;
    TOT_LIGNES_HT: number;
    TOT_LIGNES_TTC: number;
    TOT_LIGN_REMISABLES_HT: number;
    TOT_LIGN_REMISABLES_TTC: number;
    TOT_REMISES_LIGNES: number;
    TOT_REM_PIED_BASE: number;
    TOT_REMI_PIED_TARIF: number;
    TOT_REM_PIED_LIBRE: number;
    SO_TOT_PIED_HT: number;
    SO_TOT_PIED_TTC: number;
    TOT_PIED_HT: number;
    TOT_PIED_TTC: number;
    TOT_DOCUMENT_HT: number;
    TOT_DOCUMENT_TTC: number;
    MONTANT_CAS_SPECIFIQUE: number;
    TOT_POIDS_DOUCM_NET: number;
    UNITE_POIDS_NET: string;
    TOT_POIDS_DOCUM_BRUT: number;
    UNITE_POIDS_BRUT: string;
    RESERVE_POIDS_1: number;
    NOS_REFERENCES: string;
    VOS_REFERENCES: string;
    NO_PIECE_TIERS: string;
    TRAITEMENT_REMISE: boolean;
    REMISE_BASE_PIED: number;
    REMISE_TARIF_PIED: number;
    REMISE_LIBRE_PIED: number;
    CONDITIONNEMENT: string;
    MODE_DE_LIVRAISON: string;
    NO_COLIS: number;
    REMARQUE_MVT_STOCK: string;
    CD_FILIALE: string;
    CD_DEPOT: string;
    CD_FILIALE_TRANSFERT: string;
    CD_DEPOT_TRANSFERT: string;
    TRI_EDITION_TETE: number;
    RUPTURE_EDITION: number;
    ST_CONFIRME_TETE: number;
    ST_PROPOSE_TETE: number;
    ST_LIVRE_TETE: number;
    ST_FACTURE_TETE: number;
    CD_UTILISATEUR: string;
    CONCERNE: string;
    DATE_VALEUR: number;
    ID: number;
    DLU: Date;
    MODIF_DATE_LIVR: number;
    REF_DOCUMENT_EXT: string;
    NB_COPIE_BL: number;
}

export const VE_TETE_DOCUM =  Fields<IVE_TETE_DOCUM>();