import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import { Template } from 'devextreme-react/core/template';

import UserPanel from '../user-panel/user-panel';

import './header.scss';

interface IProps {
	menuToggleEnabled: boolean;
	title?: string;
	appMode: string;
	toggleMenu: (e: any) => void; 
	userMenuItems?: any;
	cssClass?: string;
}

const Header = ({ menuToggleEnabled, title, toggleMenu, cssClass }: IProps) => (
	<header className={`header-component ${cssClass}`}>
		<Toolbar className={'header-toolbar'}>
			<Item
				visible={menuToggleEnabled}
				location={'before'}
				widget={'dxButton'}
				cssClass={'menu-button'}
			>
				<Button icon="menu" stylingMode="text" onClick={toggleMenu} />
			</Item>
			<Item
				location={'before'}
				cssClass={'header-title'}
				text={title}
				visible={!!title}
			/>
			
			{/*appMode !== 'scanette' && <Item*/}
			<Item
				location={'after'}
				locateInMenu={'auto'}
				menuItemTemplate={'userPanelTemplate'}
			>
				<Button
					className={'user-button authorization'}
					width={'auto'}
					height={'100%'}
					stylingMode={'text'}
				>
					<UserPanel menuMode={'context'} />
				</Button>
			</Item>

			<Template name={'userPanelTemplate'}>
				<UserPanel menuMode={'list'} />
			</Template>
			
			
		</Toolbar>
	</header>
);

export default Header;