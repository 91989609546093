import React from 'react';

import './deductions.scss';
import {IPackHeures} from "../../models/PackHeures";
import {TabPanel} from "devextreme-react";
import {Item} from "devextreme-react/form";
import Deductions from "./deductions";


interface IDetailTabProps{
    data:
        {
            data: IPackHeures
        }
}

export default ( props : IDetailTabProps) => {


    return (
        <React.Fragment>
            <TabPanel  defaultSelectedIndex={props.data.data.CommandeTtc != 0 ? 0 : 1}>
                <Item title="Commandes" render={() => <Deductions data={props.data.data} type={"Commande"}/>}/>
                <Item title="Factures" render={() => <Deductions data={props.data.data} type={"Facture"}/>}/>
            </TabPanel>
        </React.Fragment>
    );
}