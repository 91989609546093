import {Fields} from "./Fields";

export interface IFI_TIERS_RECHERCHE  {
    CODE_SOCIETE: number;
    TY_COMPTE_TIERS: number;
    NO_TIERS: number;
    
    NO_TIERS_CHAR: string;
    RAISON_SOCIALE_NOM: string;
    NOM_ABREGE: string;
}

export const FI_TIERS_RECHERCHE =  Fields<IFI_TIERS_RECHERCHE>();