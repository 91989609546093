import React, { useEffect, useRef, useState } from 'react';

import { useAuth } from '../../contexts/auth';
import { useConf } from '../../contexts/conf';
import DataSource from 'devextreme/data/data_source';
import DataGrid, { Column, Editing, MasterDetail, Lookup, Toolbar, Item } from 'devextreme-react/data-grid';
import Notify from 'devextreme/ui/notify';
import { VE_TETE_DOCUM, IVE_TETE_DOCUM } from '../../models/VE_TETE_DOCUM';
import { FI_TIERS_RECHERCHE, IFI_TIERS_RECHERCHE } from '../../models/FI_TIERS_RECHERCHE';

import { OasisDateToNormal, NormalDateToOasis } from '../../utils/functions';
import LignesDocument from './lignes-document';

import '../../themes/scanette.scss';
import './creation-doc-vente.scss';
import { useHistory } from "react-router-dom";
import { Button } from 'devextreme-react/button';


export default function CreationDocVentePage() {

	const [loading, setLoading] = useState<boolean>(true);

	let dataGridRef = useRef<any>(null);
	let editingRef = useRef<any>(null);

	const { oDataStore } = useConf();
	const { user} = useAuth();
	
	let selectedKey: {CODE_SOCIETE: number, NO_DOCUMENT: number, TY_TRAIT_DOC_VENTE: number} | null = null;

	useEffect(() => {
		if (!loading) {
			dataGridRef.current.component.addRow();
			return () => {
				dataGridRef.current.component.getVisibleRows().forEach((row: any) => {
					validDocument(row.data);
				});
			}
		}
	}, [loading]);

	const setSelectedKey = (key: any) => {
		if (selectedKey !== null) {

			const rowElem = dataGridRef.current.component.getRowElement(dataGridRef.current.component.getRowIndexByKey(selectedKey));
			if (rowElem !== undefined) {
				rowElem[0].classList.remove("row-expand");
			}
			

		}
		if (key !== null) {
			const rowElem = dataGridRef.current.component.getRowElement(dataGridRef.current.component.getRowIndexByKey(key));
			rowElem[0].classList.add("row-expand");
		}
		selectedKey = key;
	}


	const tetesDocumentsVente = new DataSource({
		// @ts-ignore
		store: oDataStore.OdataTetesDocumentsVente,
		select: [
			VE_TETE_DOCUM.ID, 
			VE_TETE_DOCUM.NO_DOCUMENT, 
			VE_TETE_DOCUM.CODE_SOCIETE, 
			VE_TETE_DOCUM.TY_TRAIT_DOC_VENTE, 
			VE_TETE_DOCUM.DATE_DOCUMENT, 
			VE_TETE_DOCUM.NO_TIERS,
			VE_TETE_DOCUM.NOM_ABREGE, 
			VE_TETE_DOCUM.RAISON_SOCIALE_NOM, 
			VE_TETE_DOCUM.CD_DEVISE,
			VE_TETE_DOCUM.TOT_LIGNES_TTC,
			VE_TETE_DOCUM.NO_PIECE_TIERS,
			VE_TETE_DOCUM.VOS_REFERENCES,
			VE_TETE_DOCUM.CONCERNE
		],
		filter: [
			[ VE_TETE_DOCUM.CODE_SOCIETE, '=', user?.GnUser?.CODE_SOCIETE ],
			[ VE_TETE_DOCUM.TY_TRAIT_DOC_VENTE, '=', 5 ],
			[ VE_TETE_DOCUM.CD_UTILISATEUR, '=', user?.GnUser?.CD_UTILISATEUR ],
			//[ VE_TETE_DOCUM.DATE_DOCUMENT, '>=', NormalDateToOasis(new Date(new Date().setHours(0,0,0,0))) ],
			[ VE_TETE_DOCUM.ST_CONFIRME_TETE, '=', 9 ]
		],
		onLoadingChanged(isLoading) {
			if (!isLoading) {
				setLoading(false);
			}
		},
	});

	const tiersRecherche = {
		// @ts-ignore
		store: oDataStore.OdataTiersRecherche,
		select: [
			FI_TIERS_RECHERCHE.CODE_SOCIETE,
			FI_TIERS_RECHERCHE.NO_TIERS,
			FI_TIERS_RECHERCHE.TY_COMPTE_TIERS,
			FI_TIERS_RECHERCHE.NO_TIERS_CHAR,
			FI_TIERS_RECHERCHE.NOM_ABREGE,
			FI_TIERS_RECHERCHE.RAISON_SOCIALE_NOM
		],
		filter: [
			[ FI_TIERS_RECHERCHE.CODE_SOCIETE, '=', user?.GnUser?.CODE_SOCIETE ],
			[ FI_TIERS_RECHERCHE.TY_COMPTE_TIERS, '=', 1 ]
		],
		paginate: true,
		pageSize: 20
	};

	const setNewTeteData = (e: any) => {
		
		e.component.collapseAll(-1);
		setSelectedKey(null);
		
		e.component.getVisibleRows().forEach((row: any) => {
			validDocument(row.data);
		});

		e.data.CODE_SOCIETE = user?.GnUser?.CODE_SOCIETE;
		e.data.TY_SAISIE_DOC = 5;
		e.data.TY_COMPTE_TIERS = 1;
		e.data.TY_TRAIT_DOC_VENTE = 5;
		//e.data.NO_TIERS = client[0];
		e.data.NO_EXERCICE = new Date().getFullYear();
		e.data.DATE_DOCUMENT = NormalDateToOasis(new Date());
		e.data.DATE_LIVRAISON = NormalDateToOasis(new Date());
		e.data.CD_DEVISE = "CHF";
		e.data.NOTE_CREDIT_AVEC_SANS = true;
		e.data.CD_UTILISATEUR = user?.GnUser?.CD_UTILISATEUR;
		e.data.ST_CONFIRME_TETE = 9;
	}

	const onDocumentAdded = async (e: any) => {
		//e.component.collapseAll(-1);
		await e.component.expandRow(e.key);
		setSelectedKey(e.key);
	}

	const expandSelection = async (e: any) => {
		
		if (e.rowType === 'data') {
			if (e.isExpanded) {
				await e.component.collapseRow(e.key);
				setSelectedKey(null);
			}
			else {
				e.component.collapseAll(-1);
				await e.component.expandRow(e.key);
				setSelectedKey(e.key);
			}
		}
		
	}

	const rowPrepared = (e: any) => {

		if (e.rowType === 'data') {
		
			if (selectedKey !== null && e.key && e.key.NO_DOCUMENT === selectedKey.NO_DOCUMENT) {
				e.rowElement.classList.add("row-expand");
			}

		}
	}

	const validDocument = (doc: IVE_TETE_DOCUM) => {
		// @ts-ignore
		const res = oDataStore.OdataTetesDocumentsVente.update(
			{
				CODE_SOCIETE: doc.CODE_SOCIETE,
				TY_TRAIT_DOC_VENTE: doc.TY_TRAIT_DOC_VENTE,
				NO_DOCUMENT: doc.NO_DOCUMENT
			},
			{
				ST_CONFIRME_TETE: 0
			}
		);
		res.done(Notify({message: "Document validé", width: "auto", position: "bottom center", displayTime: 2000}, "success"));
		tetesDocumentsVente.reload();
	}

	const history = useHistory();

	const unvalidDocuments = async () => {
		await dataGridRef.current.component.getVisibleRows().forEach(({data: doc}: any) => {
			// @ts-ignore
			const res = oDataStore.OdataTetesDocumentsVente.update(
			{
				CODE_SOCIETE: doc.CODE_SOCIETE,
				TY_TRAIT_DOC_VENTE: doc.TY_TRAIT_DOC_VENTE,
				NO_DOCUMENT: doc.NO_DOCUMENT
			},
			{
				ST_CONFIRME_TETE: 0,
				ST_LIVRE_TETE: 2,
				ST_FACTURE_TETE: 2
			});

			res.done(Notify({message: "Document annulé", width: "auto", position: "bottom center", displayTime: 2000}, "warning"));
		});

		await tetesDocumentsVente.reload();
		history.push("/");
		
	}

	return (
		<React.Fragment>
			<h3 className={'content-block'}>Création doc. vente</h3>
			
			<div className={'content-block'}>
					
				<DataGrid 
					columnAutoWidth={true}
					className={'dx-card wide-card'}
					dataSource={tetesDocumentsVente} 
					allowColumnResizing={true}
					focusedRowEnabled={false}
					rowAlternationEnabled={true}
					repaintChangesOnly={true}
					onInitNewRow={setNewTeteData}
					onRowInserted={onDocumentAdded}
					onRowClick={expandSelection}
					onRowPrepared={rowPrepared}
					onInitialized={(e) => {
						dataGridRef.current = e;
					}}
				>

					{/*<Selection mode="single" />*/}

					<Toolbar>
						<Item location="before">
							<Button hint="Annuler" icon="close" onClick={unvalidDocuments} text='Annuler'/>
						</Item>
						<Item name="addRowButton" showText="always" />
					</Toolbar>

					<Editing
						mode="popup"
						allowUpdating={false}
						allowDeleting={false}
						allowAdding={true} 
						useIcons={true} 
						texts={{
							saveRowChanges: "Ajouter le document",
						}}
						ref={(e) => {
							editingRef.current = e;
						}}
					/>

						<Column dataField={VE_TETE_DOCUM.NO_DOCUMENT} caption="No. commande" allowEditing={false} formItem={{visible: false}} sortOrder={'desc'} />
						<Column dataField={VE_TETE_DOCUM.DATE_DOCUMENT} caption="Date" allowEditing={false} formItem={{visible: false}} calculateCellValue={(e: any) => OasisDateToNormal(e.DATE_DOCUMENT)} />
						<Column calculateDisplayValue={(teteDocument: IVE_TETE_DOCUM) => teteDocument.NOM_ABREGE + " (" + teteDocument.RAISON_SOCIALE_NOM + ")"} dataField={VE_TETE_DOCUM.RAISON_SOCIALE_NOM} caption="Client" allowEditing={false} formItem={{visible: false}} allowSorting={false} /*hidingPriority={1}*//>
						{/*<Column dataField={VE_TETE_DOCUM.TOT_LIGNES_TTC} format={{type: "currency", currency: "CHF", precision: 2}} allowEditing={false} formItem={{visible: false}}  allowSorting={false} hidingPriority={0}/>*/}
						{/*<Column type="buttons">
							<Button hint="Valider" icon="check" onClick={(e) => validDocument(e.row.data)} />
						</Column>*/}
						
						<Column dataField={VE_TETE_DOCUM.NO_TIERS} calculateDisplayValue={VE_TETE_DOCUM.NO_TIERS} visible={false} dataType='number' editorOptions={{searchExpr: [FI_TIERS_RECHERCHE.NOM_ABREGE, FI_TIERS_RECHERCHE.RAISON_SOCIALE_NOM, FI_TIERS_RECHERCHE.NO_TIERS_CHAR], searchMode: "contains"}} >
							<Lookup
								// @ts-ignore
								dataSource={tiersRecherche}
								valueExpr={FI_TIERS_RECHERCHE.NO_TIERS}
								displayExpr={(tierRecherche: IFI_TIERS_RECHERCHE) => tierRecherche.NOM_ABREGE + " (" + tierRecherche.RAISON_SOCIALE_NOM + ") " + tierRecherche.NO_TIERS_CHAR}
								allowClearing={true}
							/>
						</Column>
						{/*<Column dataField={VE_TETE_DOCUM.NO_PIECE_TIERS} caption="No. Pièce Client" visible={false} />
						<Column dataField={VE_TETE_DOCUM.VOS_REFERENCES} visible={false} />
						<Column dataField={VE_TETE_DOCUM.CONCERNE} visible={false} />*/}
						
						<MasterDetail
							enabled={false}
							component={LignesDocument}
						/>

				</DataGrid>
			</div>

				
		</React.Fragment>
	)
};