import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';

import { Template } from 'devextreme-react/core/template';
import Button from 'devextreme-react/button';
import Drawer from 'devextreme-react/drawer';
import ScrollView from 'devextreme-react/scroll-view';
import Toolbar, { Item } from 'devextreme-react/toolbar';

import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import SideNavigationMenu from '../../components/side-navigation-menu/side-navigation-menu';
import { useScreenSize } from '../../utils/media-query';
import { useMenuPatch } from '../../utils/patches';

import './authenticated.scss';

interface IProps {
    title: string;
	appMode: string;
    children: any;
}

const AuthenticatedLayout = function ({ title, appMode, children }: IProps) {
	const location = useLocation();
	const [menuTitle, setMenuTitle] = useState<string>('');
	const scrollViewRef = useRef<ScrollView>(null);
	const history = useHistory();
	const { isXSmall, isLarge } = useScreenSize();
	const [patchCssClass, onMenuReady] = useMenuPatch();
	const [menuStatus, setMenuStatus] = useState( isLarge ? MenuStatus.Opened : MenuStatus.Closed );

	useEffect(() => {
		if (location.pathname === '/creation-doc-vente') {
			setMenuTitle('Terminer');
		}
		else {
			setMenuTitle('');
		}
	}, [location])

	const toggleMenu = useCallback(({ event }) => {
		setMenuStatus( prevMenuStatus => prevMenuStatus === MenuStatus.Closed ? MenuStatus.Opened : MenuStatus.Closed );
		event.stopPropagation();
	}, []);

	const temporaryOpenMenu = useCallback(() => {
		setMenuStatus( prevMenuStatus => prevMenuStatus === MenuStatus.Closed ? MenuStatus.TemporaryOpened : prevMenuStatus );
	}, []);

	const onOutsideClick = useCallback(() => {
		setMenuStatus( prevMenuStatus => prevMenuStatus !== MenuStatus.Closed && !isLarge ? MenuStatus.Closed : prevMenuStatus );
		return true;
	}, [isLarge]);

	const onNavigationChanged = useCallback(({ itemData: { path }, event, node }) => {
		if (menuStatus === MenuStatus.Closed || !path || node.selected) {
			event.preventDefault();
			return;
		}

		history.push(path);
		scrollViewRef.current?.instance.scrollTo(0);

		if (!isLarge || menuStatus === MenuStatus.TemporaryOpened) {
			setMenuStatus(MenuStatus.Closed);
			event.stopPropagation();
		}
	}, [history, menuStatus, isLarge]);

	
	switch(appMode) {
		case 'default': return (
			<div className={'side-nav-inner-toolbar'}>
				<Drawer
					className={['drawer', patchCssClass].join(' ')}
					position={'before'}
					closeOnOutsideClick={onOutsideClick}
					openedStateMode={isLarge ? 'shrink' : 'overlap'}
					revealMode={isXSmall ? 'slide' : 'expand'}
					minSize={isXSmall ? 0 : 60}
					maxSize={250}
					shading={isLarge ? false : true}
					opened={menuStatus === MenuStatus.Closed ? false : true}
					template={'menu'}
					>

					<div className={'container'}>
						<Header
							appMode={appMode}
							menuToggleEnabled={isXSmall}
							toggleMenu={toggleMenu}
							title={menuTitle}
						/>
						<ScrollView ref={scrollViewRef} className={'layout-body with-footer'}>
							<div className={'content'}>
								{React.Children.map(children, item => {
									return item.type !== Footer && item;
								})}
							</div>
							<div className={'content-block'}>
								{React.Children.map(children, item => {
									return item.type === Footer && item;
								})}
							</div>
						</ScrollView>
					</div>

					<Template name={'menu'}>

						<SideNavigationMenu
							compactMode={menuStatus === MenuStatus.Closed}
							selectedItemChanged={onNavigationChanged}
							openMenu={temporaryOpenMenu}
							onMenuReady={onMenuReady}
						>
							<Toolbar id={'navigation-header'}>
								{!isXSmall &&
								<Item
									location={'before'}
									cssClass={'menu-button'}
									>
									<Button icon="menu" stylingMode="text" onClick={toggleMenu} />
								</Item>
								}

								<Item location={'before'} cssClass={'header-title'} text={title} />
							</Toolbar>
						</SideNavigationMenu>

					</Template>

				</Drawer>
			</div>
		);

		case 'scanette' : return (
			<Drawer>
				<div className={'container'} style={{flexGrow: 1}}>
					<Header
						appMode={appMode}
						cssClass={"header-scanette"}
						menuToggleEnabled={true}
						toggleMenu={() => (history.push('/home'))}
						title={menuTitle}
					/>

					<ScrollView ref={scrollViewRef} className={'layout-body with-footer'}>
						<div className={'content'}>
							{React.Children.map(children, item => {
								if (item.type !== Footer) {
									
									return React.cloneElement(item, { scrollViewRef: scrollViewRef });
								}
							})}
						</div>
					</ScrollView>
				</div>
			</Drawer>
		)
		default: return (
			<div className={'container'} style={{flexGrow: 1}}>Aucun layout détecté</div>
		)
	}
}

const MenuStatus = {
	Closed: 1,
	Opened: 2,
	TemporaryOpened: 3
};

export default AuthenticatedLayout;