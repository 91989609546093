import { ODataContextOptions } from "devextreme/data/odata/context";

const oasisContext: ODataContextOptions = {
    version: 4,
    withCredentials: true,
    entities: {
        OdataArticles: {
            key: 'ID'
        },
        OdataCategoriesLien: {
            key: 'ID'
        },
        OdataTetesDocumentsVente: {
            key: ['CODE_SOCIETE', 'TY_TRAIT_DOC_VENTE', 'NO_DOCUMENT'],
            keyType: {
                CODE_SOCIETE: "Int32",
                TY_TRAIT_DOC_VENTE: "Int32",
                NO_DOCUMENT: "Int32"
            }
        },
        OdataLignesDocumentsVente: {
            key: ["CODE_SOCIETE", "TY_TRAIT_DOC_VENTE", "NO_DOCUMENT", "NO_BON_LIVRE_RECAP", "NO_LIGNE_DOCUMENT"],
            keyType: {
                CODE_SOCIETE: "Int32",
                TY_TRAIT_DOC_VENTE: "Int32",
                NO_DOCUMENT: "Int32",
                NO_BON_LIVRE_RECAP: "Int32",
                NO_LIGNE_DOCUMENT: "Int32"
            }
        },
        OdataTiers: {
            key: ['CODE_SOCIETE', 'TY_COMPTE_TIERS', 'NO_TIERS'],
            keyType: {
                CODE_SOCIETE: "Int32",
                TY_COMPTE_TIERS: "Int32",
                NO_TIERS: "Int32"
            },
            fieldTypes: {
                NO_TIERS: 'Int32'
            }
        },
        OdataTiersRecherche: {
            key: ['CODE_SOCIETE', 'TY_COMPTE_TIERS', 'NO_TIERS'],
            keyType: {
                CODE_SOCIETE: "Int32",
                TY_COMPTE_TIERS: "Int32",
                NO_TIERS: "Int32"
            },
            fieldTypes: {
                NO_TIERS: 'Int32'
            }
        },
        OdataPalettes: {
            key: 'ID'
        },
    },
    beforeSend: (request: any) => {

        //if (request.method == 'PATCH') {
        //    request.method = 'PUT';
        //}

        const token: string | null = localStorage.getItem('oasisWebUser') || sessionStorage.getItem('oasisWebUser');

        if (token) {
            request.headers["Authorization"] = "Bearer " + token;
        }
    },
    errorHandler: (err: any) => {
        console.log(err);
    }
};

export default oasisContext;