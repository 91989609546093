import {Fields} from "./Fields";
import {IGN_FILIALES} from "./GN_FILIALES";
import {IST_DEPOTS} from "./ST_DEPOTS";
import {IST_TYPE_ARTICLE} from "./ST_TYPE_ARTICLE";
import {IST_FAMILLE_ARTICLE} from "./ST_FAMILLE_ARTICLE";
import {IST_SOUS_FAM_ART} from "./ST_SOUS_FAM_ART";
import {IST_UNITE_QUANTITE} from "./ST_UNITE_QUANTITE";

export interface IST_ARTICLE  {
    CODE_SOCIETE: number;
    NO_STRUCTURE_REF_A: number;
    NO_PICTURE_REF_A: number;
    REFERENCE_ARTICLE_A: string;
    NO_STRUCTURE_REF_B: number;
    NO_PICTURE_REF_B: number;
    REFERENCE_ARTICLE_B: string;
    DESIGN_1_ARTICLE: string;
    DESIGN_2_ARTICLE: string;
    DESIGN_3_ARTICLE: string;
    DESIGN_4_ARTICLE: string;
    CD_TVA: number;
    CD_FILIALE_DEFAUT: string;
    ParentGN_FILIALES: IGN_FILIALES;
    CD_DEPOT: string;
    ParentCD_DEPOT: IST_DEPOTS;
    INDICE_VARIANTE: number;
    INDICE_GAMME: number;
    CD_NATURE_ARTICLE: number;
    CD_COMBINE_PRODUCTION: number;
    CD_TYPE_ART: string;
    ParentST_TYPE_ARTICLE: IST_TYPE_ARTICLE;
    CD_FAMILLE_ART: string;
    ParentST_FAMILLE_ARTICLE: IST_FAMILLE_ARTICLE;
    CD_SOUS_FAMILLE: string;
    ParentST_SOUS_FAM_ART: IST_SOUS_FAM_ART;
    CD_GROUPE_PRIX: number;
    CD_TRACABILITE: number;
    UNITE_STOCKAGE: string;
    ParentST_UNITE_QUANTITE_Stockage: IST_UNITE_QUANTITE;
    UNITE_PRIX: number;
    PRIX_STANDARD: number;
    UNITE_ACHATS_DEFAUT: string;
    ParentST_UNITE_QUANTITE_Achat: IST_UNITE_QUANTITE;
    FACT_CONV_ACHATS_DEFAUT: number;
    FACTEUR_VARIABLE_ACHAT: boolean;
    CENTRE_ACHATS: string;
    NO_FOURNISSEUR_DEFAUT: number;
    QTE_MIN_REAPPRO: number;
    DELAI_REAPPRO: number;
    TY_REGROUP_CMDE: number;
    TY_RECEPTION_MARCH: number;
    MODE_PRELEVEMENT: number;
    DERN_PRIX_ACHAT: number;
    DATE_MAJ_PRIX_ACHAT: number;
    DATE_MAJ_PRIX_ACHATDateTime: Date;
    PRIX_MOYEN_PONDERE: number;
    QTE_NON_VALORISEE: number;
    VAL_THE_DU_NON_VALORSIE: number;
    UNITE_VENTES_DEFAUT: string;
    ParentST_UNITE_QUANTITE_Vente: IST_UNITE_QUANTITE;
    FACT_CONV_VENTES_DEFAUT: number;
    FACTEUR_VARIABLE_VTE: boolean;
    CENTRE_VENTES: string;
    MODE_CALCUL_PRIX: number;
    PRIX_UNITAIRE_INITIAL: number;
    QTE_TEMP_CALCUL_REVIENT: number;
    DATE_MAJ_PRIX_VENTE: number;
    QTE_FIXE_PROP_LIV_VENTE: number;
    QTE_MINIMUM_VENTE: number;
    CD_INTEGRATION: number;
    CD_REMISE_BASE_LIGNE: number;
    CD_QTE_TARIF: number;
    CD_RABAIS_MARCHE: number;
    CD_REMISE_TARIF: number;
    CD_REMISE_QTE: number;
    CD_COMMISSION: number;
    FACT_CONV_CONSO: number;
    UNITE_CONSOMMATION: string;
    ParentST_UNITE_QUANTITE_Consommation: IST_UNITE_QUANTITE;
    LOT_ECONOMIQUE: number;
    REBUT_POURCENT: number;
    CD_SOURCE: number;
    CD_VALORISATION: number;
    NO_PAGE_CATALOGUE: string;
    NO_TARIF_DOUANIER: string;
    FALG_CMDE_AFFECTEE: boolean;
    FLAG_HISTORIQUE: boolean;
    FALG_CHIFFRE_AFFAIRE: boolean;
    FLAG_REMISABLE: boolean;
    DATE_CREATION: number;
    DATE_CREATIONDateTime: Date;
    DATE_DERN_MODIF: number;
    DATE_DERN_MODIFDateTime: Date;
    CD_UTILISATEUR: string;
    FLAG_NUM_REORG: boolean;
    PRIX_MOYEN_CALCULE: number;
    CUMUL_QTES_ENTREES: number;
    CUMUL_VAL_ENTREES: number;
    REORG_INDEX_EXT: boolean;
    CD_TRAIT_COMPOSANTS: number;
    CD_REMISE_GROUPEE: number;
    CD_PREPARATION: number;
    FLAG_INSTALLATION_SAV: boolean;
    FALG_RESERVE_3: boolean;
    FALG_RESERVE_2: boolean;
    FALG_RESERVE_1: boolean;
    ID: number;
    DLU: Date;
    ROTATION_STOCKS: number;
    SOUMIS_RPLP: boolean;
    ANNULATION_RELIQUAT_AUTO: boolean;
}

export const ST_ARTICLE =  Fields<IST_ARTICLE>();